.contactPage{
    display: flex;
    flex-direction: column;
}
.contactPage .contact-sec{
    height: auto;
    padding: 0;
    margin-top: 100px;
    padding: 50px 10px;
}
.c-card{
    box-shadow: none !important;
}