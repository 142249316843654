.advice {
    .advice-content {
        width: 100%;
        display: flex;
        padding-top: 125px;

        .leftside {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 180px;


            .leftside-content {
                max-width: 604px;

                .header {
                    // max-width: 567px;
                    display: flex;
                    flex-direction: column;
                    gap: 26px;

                    h5 {
                        color: #2B2B2B;

                        font-family: "Readex Pro";
                        font-size: 2.5vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -1.92px;
                    }

                    p {
                        // max-width: 520px;
                        color: #707070;

                        font-family: "Inter";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.72px;
                    }
                }

                .middle-section {
                    margin-top: 71px;
                    display: flex;
                    justify-content: space-between;
                    gap: 59px;
                    margin-bottom: 40px;

                    .middle-left {
                        h5 {
                            color: #2B2B2B;

                            font-family: "Readex Pro";
                            font-size: 1.667vw;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -1.28px;
                            margin-bottom: 26px;
                        }

                        p {
                            color: #707070;
                            font-family: "Inter";
                            font-size: 0.833vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }

                    .middle-right {
                        h5 {
                            color: #2B2B2B;

                            font-family: "Readex Pro";
                            font-size: 1.667vw;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -1.28px;
                            margin-bottom: 26px;
                        }

                        p {
                            color: #707070;
                            font-family: "Inter";
                            font-size: 0.833vw;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.64px;
                        }
                    }
                }

                button {
                    // width: 154px;
                    padding: 16px 32px;
                    border-radius: 8px;
                    background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 1.146vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    border: none;
                }
            }
        }

        .rightside {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .rightside-content {
                max-width: 677px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .expert-content {
        width: 100%;
        display: flex;


        .expertleft-content {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 220px;
            padding-top: 50px;

            .expert-primary {
                max-width: 650px;

                h5 {
                    color: #2B2B2B;
                    font-family: "Readex Pro";
                    font-size: 3.177vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -2.44px;
                }
            }
        }

        .expertright-content {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .right-primary {
                max-width: 624px;
                padding: 115px 0px 39px 0px;

                p {
                    color: #707070;

                    font-family: "Inter";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }
        }
    }

    button {
        // width: 154px;
        padding: 16px 32px;
        border-radius: 8px;
        background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
        color: #FFF;
        font-family: "Inter";
        font-size: 1.146vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border: none;
    }
}

@media only screen and (max-width: 1600px) {
    .advice {
        .advice-content {
            width: 100%;
            display: flex;
            padding-top: 125px;

            .leftside {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;


                .leftside-content {
                    max-width: 604px;

                    .header {
                        // max-width: 567px;
                        display: flex;
                        flex-direction: column;
                        gap: 26px;

                        h5 {
                            color: #2B2B2B;

                            font-family: "Readex Pro";
                            font-size: 2.5vw;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -1.92px;
                        }

                        p {
                            // max-width: 520px;
                            color: #707070;

                            font-family: "Inter";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.72px;
                        }
                    }

                    .middle-section {
                        margin-top: 71px;
                        display: flex;
                        justify-content: space-between;
                        gap: 59px;
                        margin-bottom: 40px;

                        .middle-left {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 0.833vw;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }

                        .middle-right {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 0.833vw;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }
                    }

                    button {
                        // width: 154px;
                        padding: 16px 32px;
                        border-radius: 8px;
                        background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 1.146vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }
                }
            }

            .rightside {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;

                .rightside-content {
                    max-width: 677px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .expert-content {
            width: 100%;
            display: flex;


            .expertleft-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 50px;
                padding-top: 50px;

                .expert-primary {
                    max-width: 650px;

                    h5 {
                        color: #2B2B2B;
                        font-family: "Readex Pro";
                        font-size: 3.177vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -2.44px;
                    }
                }
            }

            .expertright-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .right-primary {
                    max-width: 510px;
                    padding: 115px 0px 39px 0px;

                    p {
                        color: #707070;

                        font-family: "Inter";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .advice {
        .advice-content {
            width: 100%;
            display: flex;
            padding-top: 125px;

            .leftside {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;


                .leftside-content {
                    max-width: 604px;

                    .header {
                        // max-width: 567px;
                        display: flex;
                        flex-direction: column;
                        gap: 26px;

                        h5 {
                            color: #2B2B2B;

                            font-family: "Readex Pro";
                            font-size: 2.5vw;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -1.92px;
                        }

                        p {
                            // max-width: 520px;
                            color: #707070;

                            font-family: "Inter";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.72px;
                        }
                    }

                    .middle-section {
                        margin-top: 71px;
                        display: flex;
                        justify-content: space-between;
                        gap: 59px;
                        margin-bottom: 40px;

                        .middle-left {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }

                        .middle-right {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }
                    }

                    button {
                        // width: 154px;
                        padding: 16px 32px;
                        border-radius: 8px;
                        background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 1.146vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }
                }
            }

            .rightside {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;

                .rightside-content {
                    max-width: 677px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .expert-content {
            width: 100%;
            display: flex;


            .expertleft-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
                padding-top: 50px;

                .expert-primary {
                    max-width: 650px;

                    h5 {
                        color: #2B2B2B;
                        font-family: "Readex Pro";
                        font-size: 3.177vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -2.44px;
                    }
                }
            }

            .expertright-content {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .right-primary {
                    max-width: 510px;
                    padding: 115px 0px 39px 0px;

                    p {
                        color: #707070;

                        font-family: "Inter";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .advice {
        .advice-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 0px;
            padding-bottom: 40px;

            .leftside {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
                padding-right: 10px;
                text-align: center;
                order: 2;


                .leftside-content {
                    max-width: 604px;

                    .header {
                        // max-width: 567px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        h5 {
                            color: #2B2B2B;

                            font-family: "Readex Pro";
                            font-size: 22px !important;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: -1.92px;
                        }

                        p {
                            // max-width: 520px;
                            color: #707070;

                            font-family: "Inter";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            letter-spacing: -0.72px;
                        }
                    }

                    .middle-section {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        gap: 30px;
                        margin-bottom: 30px;

                        .middle-left {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                                margin-bottom: 10px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }

                        .middle-right {
                            h5 {
                                color: #2B2B2B;

                                font-family: "Readex Pro";
                                font-size: 1.667vw;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: -1.28px;
                                margin-bottom: 10px;
                            }

                            p {
                                color: #707070;
                                font-family: "Inter";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                letter-spacing: -0.64px;
                            }
                        }
                    }

                    button {
                        // width: 154px;
                        padding: 16px 32px;
                        border-radius: 8px;
                        background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 1.146vw;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;
                        border: none;
                    }
                }
            }

            .rightside {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;

                .rightside-content {
                    max-width: 677px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .expert-content {
            width: 100%;
            display: flex;
            flex-direction: column;


            .expertleft-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
                padding-top: 10px;
                text-align: center;

                .expert-primary {
                    // max-width: 650px;
                    width: 100%;

                    h5 {
                        color: #2B2B2B;
                        font-family: "Readex Pro";
                        font-size: 22px !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: -0.14px;
                    }
                }
            }

            .expertright-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .right-primary {
                    max-width: 510px;
                    padding: 20px 10px 20px 10px;
                    text-align: center;

                    p {
                        color: #707070;

                        font-family: "Inter";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: -0.88px;
                    }
                }
            }
        }
    }
}