.agentcard {
    .agentcard-content {
        background-color: antiquewhite;
        max-width: 390px;
        // height: 469px;
        border-radius: 24px;
        background: #FFF;
        box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.30);
        padding: 50px 43px 50px 43px;
        height: 100%;

        .title-img {
            text-align: center;
            padding-bottom: 10px;

        }

        .title {
            margin-top: 46px;

            p {
                color: #2B2B2B;
                text-align: center;

                font-family: "Readex Pro";
                font-size: 1.146vw;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.88px;
            }
        }

        .subtitle {
            margin-top: 36px;

            p {
                color: #707070;
                text-align: center;

                font-family: "Inter";
                font-size: 0.833vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.64px;
            }
        }

        .card-btn {
            text-align: center;
            margin-top: 31px;

            button {
                padding: 16px 32px;
                border-radius: 8px;
                background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                border: none;
                cursor: pointer;
                color: #FFF;
                font-family: "Inter";
                font-size: 1.146vw;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .agentcard {
        .agentcard-content {
            background-color: antiquewhite;
            // max-width: 390px;
            max-width: 600px;
            // height: 469px;
            border-radius: 24px;
            background: #FFF;
            box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.30);
            padding: 40px 20px 40px 20px;
            height: 100%;

            .title-img {
                text-align: center;
            }

            .title {
                margin-top: 46px;

                p {
                    color: #2B2B2B;
                    text-align: center;

                    font-family: "Readex Pro";
                    font-size: 23px !important;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .subtitle {
                margin-top: 36px;

                p {
                    color: #707070;
                    text-align: center;

                    font-family: "Inter";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }
            }

            .card-btn {
                text-align: center;
                margin-top: 31px;

                button {
                    padding: 16px 32px;
                    border-radius: 8px;
                    background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    border: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 1.146vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .agentcard {
        .agentcard-content {
            background-color: antiquewhite;
            // max-width: 390px;
            max-width: 600px;
            // height: 469px;
            border-radius: 24px;
            background: #FFF;
            box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.30);
            padding: 40px 20px 40px 20px;
            height: 100%;

            .title-img {
                text-align: center;
            }

            .title {
                margin-top: 46px;

                p {
                    color: #2B2B2B;
                    text-align: center;

                    font-family: "Readex Pro";
                    font-size: 23px !important;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .subtitle {
                margin-top: 36px;

                p {
                    color: #707070;
                    text-align: center;

                    font-family: "Inter";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }
            }

            .card-btn {
                text-align: center;
                margin-top: 31px;

                button {
                    padding: 16px 32px;
                    border-radius: 8px;
                    background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    border: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 1.146vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .agentcard {
        .agentcard-content {
            background-color: antiquewhite;
            // max-width: 390px;
            max-width: 600px;
            // height: 469px;
            border-radius: 24px;
            background: #FFF;
            box-shadow: 0px 4px 24.6px 0px rgba(74, 150, 246, 0.30);
            padding: 40px 20px 40px 20px;
            height: 100%;

            .title-img {
                text-align: center;
            }

            .title {
                margin-top: 46px;

                p {
                    color: #2B2B2B;
                    text-align: center;

                    font-family: "Readex Pro";
                    font-size: 20px !important;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }

            .subtitle {
                margin-top: 36px;

                p {
                    color: #707070;
                    text-align: center;

                    font-family: "Inter";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.64px;
                }
            }

            .card-btn {
                text-align: center;
                margin-top: 31px;

                button {
                    padding: 16px 32px;
                    border-radius: 8px;
                    background: var(--gradient, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                    border: none;
                    cursor: pointer;
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 1.146vw;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }
        }
    }
}