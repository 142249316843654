:root {
  --black: #000000;
  --white: #ffffff;
  --hanblue: #4176d4;
  --quartzz: #4b4b4b;
  --flashwhite: #f1f1f1;
  --Charlestongreen: #2b2b2b;
  --aliceblue: #f0f1fd;
  --f3f3f3: #f3f3f3;
  --a5a5a5: #a5a5a5;
  --dd: #8d8d8d;
  --aef: #497aef;
  --red: #ff0e0e;
  --cloud: #c4c4c4;
  --F0F1FD: "#F0F1FD";

  --primary: #0089ed;
  --secondary: #e9f1ff;
  --text-primary: #0089ed;
  --text-secondary: #8d8d8d;
  --text-dark: #131313;
  --swotStrength: #e1edd3;
  --swotWeakness: #f6d6d5;
  --swotOpportunity: #bfd6e8;
  --swotThreat: #faeec9;
  --0D4D80: #0d4d80;
  --00A2D6: #00a2d6;
  --c3c3c3: #c3c3c3;
  --0D4D80: #0d4d80;
  --4285F4: #4285f4;
  --808080: #808080;
  --F6F6F6: #f6f6f6;
}

[data-theme="dark"] {
  --background-color: black;
  --bgtext: white;
}
body {
  min-height: unset !important;
  position: unset !important;
}
