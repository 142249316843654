.navbar-section {
  padding: 0px 133px 0px 133px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100px;
  .navbar-main {
    width: 100%;
    height: 100%;
    .navbar-primary {
      width: 100%;
      height: 100%;
      padding: 0px;

      .nav-ul {
        display: flex;
        gap: 85px;

        .nav-item {
          .nav-link {
            color: #5b5b5b;
            font-family: "Inter";
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      .form-search {
        margin-left: 44px;
        display: flex;
        gap: 16px;

        .sign-up {
          padding: 16px 32px;
          border-radius: 8px;
          border: 1.333px solid var(--gradient, #4276d4);
          background: rgba(250, 253, 255, 0.47);
          font-family: "Inter";
          font-size: 1.146vw;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          cursor: pointer;
          position: relative;
          color: #3f70c9;

          &:hover {
            color: white;
            border-radius: 8px;
            background: var(
              --gradient,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
          }

          &::before {
            content: "";
            width: 100%;
            height: 20px;
            position: absolute;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              0 1px 3px rgba(0, 0, 0, 0.08);
            bottom: -10px;
            left: 0;
            opacity: 0.5;
            background: var(
              --bg,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
            filter: blur(15px);
          }
        }

        .Log-in {
          font-family: "Inter";
          font-size: 1.146vw;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          padding: 16px 32px;
          border-radius: 8px;
          border: 1.333px solid var(--gradient, #4276d4);
          // background: rgba(250, 253, 255, 0.47);
          cursor: pointer;
          position: relative;
          background: var(
            --gradient,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
          color: white;

          &:hover {
            color: white;
            border-radius: 8px;
            background: var(
              --gradient,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
          }

          &::before {
            content: "";
            width: 100%;
            height: 20px;
            position: absolute;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              0 1px 3px rgba(0, 0, 0, 0.08);
            bottom: -10px;
            left: 0;
            opacity: 0.5;
            background: var(
              --bg,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
            filter: blur(15px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .navbar-section {
    padding: 0px 40px 0px 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 100px;
    .navbar-main {
      width: 100%;
      height: 100%;
      .navbar-primary {
        .nav-ul {
          display: flex;
          gap: 40px;
          .nav-item {
            .nav-link {
              color: #5b5b5b;
              font-family: "Inter";
              font-size: 1.111vw;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .form-search {
          margin-left: 44px;
          display: flex;
          gap: 16px;

          .sign-up {
            padding: 16px 32px;
            border-radius: 8px;
            border: 1.333px solid var(--gradient, #4276d4);
            background: rgba(250, 253, 255, 0.47);
            font-family: "Inter";
            font-size: 1.146vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            cursor: pointer;

            &:hover {
              color: white;
              border-radius: 8px;
              background: var(
                --gradient,
                linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
              );
            }
          }

          .Log-in {
            font-family: "Inter";
            font-size: 1.146vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            padding: 16px 32px;
            border-radius: 8px;
            border: 1.333px solid var(--gradient, #4276d4);
            background: rgba(250, 253, 255, 0.47);
            cursor: pointer;
            background: var(
              --gradient,
              linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
            );
            color: white;

            &:hover {
              color: white;
              border-radius: 8px;
              background: var(
                --gradient,
                linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
              );
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .navbar-section {
    padding: 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: transparent;

    height: 100px;
    .navbar-main {
      position: relative;
      /* Added */
      width: 100%;
      height: 100%;
      .navbar-primary {
        .navbar-brand {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }

        .navbar-toggler {
          position: absolute;
          /* Adjusted */
          top: 50%;
          /* Adjusted */
          right: 10px;
          /* Adjusted */
          transform: translateY(-50%);
          /* Adjusted */
          outline: 1px solid black;
          box-shadow: none;
        }

        .nav-ul {
          gap: 10px;
          margin-top: 90px;
          background-color: #fafdff;
          padding-bottom: 10px;
          padding-left: 10px;
          .nav-item {
            .nav-link {
              font-size: 20px;
            }
          }
        }

        .form-search {
          margin-left: 0px;
          padding-left: 10px;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 10px;
          padding-top: 10px;

          background-color: #fafdff;

          margin-top: -10px;

          .sign-up {
            font-size: 20px;
          }

          .Log-in {
            font-size: 20px;

            padding: 16px 38px;
          }
        }
      }
    }
  }
}
