.newchat {
  width: 100%;
  padding-left: 245px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 7px;
  padding-top: 30px;
  background-image: url("../../assets/images/Group 48098256.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30%;

  &[data-theme="dark"] {
    background-image: url("../../assets/images/Group 48098257.png");
    // background-image: url("../../assets/images/ssad\ dark.png");
  }

  .newchat-content {
    width: 100%;

    // margin-top: 47px;
    // border: 1px solid red;
    // height: calc(100vh - 18.5vh - 39px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 20vh);
    padding-bottom: 20px;

    .chatTitle {
      width: 100%;
      padding-bottom: 10px;

      .titleContent {
        width: fit-content;

        max-width: 800px;

        display: flex;
        flex-direction: column;
        gap: 15px;
        // height: 120px;
        border-radius: 20px 20px 20px 0px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 18px 25px 25px 25px;

        h6 {
          color: #000;
          font-family: "Readex Pro";
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }

        span {
          color: #2b2b2b;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
        }

        &[data-theme="dark"] {
          background: #2f2f2f;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

          h6 {
            color: var(--bgtext);
          }

          span {
            color: #d4d4d4;
          }
        }
      }
    }

    .parent-divquestion {
      width: 100%;
      padding-right: 275px;

      .title-question {
        width: 100%;

        .left-question {
          width: 100%;
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          button {
            width: 100%;
            cursor: pointer;
            border-radius: 10px 10px 10px 10px;
            // background: #FFF;
            background: transparent;
            // box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04), 0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04), 0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06), -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.10);
            box-shadow: 0px 0px 3.248px 0px rgba(0, 0, 0, 0.15);

            // border: 1px solid #2B2B2B;
            border: none;
            padding-block: 20px;
            // padding-inline: 20px;
            // padding: 20px;
            color: #2b2b2b;
            text-align: start;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 20px;

            &[data-theme="dark"] {
              background: #2b2b2b;
              // background: rebeccapurple;
              // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
              color: white;
            }
          }
        }
      }
    }

    .chatContent {
      width: 100%;
      padding-right: 275px !important;
      // padding-top: 81px;
      width: 100%;
      height: calc(100vh - 15.5vh - 39px);
      // height: 60vh;
      padding-right: 75px;
      overflow-y: scroll;
      -webkit-scrollbar: none;

      .userCommand {
        width: 100%;
        display: flex;
        justify-content: start;

        .userQuestion {
          border-radius: 25px 25px 25px 0px;
          background: #315191;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

          // width: fit-content;
          max-width: 100%;
          line-break: anywhere;
          // max-width: 100%;

          padding: 18px 25px 18px 25px;
          // width: 100%;
          // min-width: 94px;
          // width: auto;
          // width: 94px;
          // height: 55px;
          flex-shrink: 0;
          display: flex;
          justify-content: start;
          align-items: center;

          span {
            color: #fff;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }

          &[data-theme="dark"] {
            background: #537ece;
            // background: rebeccapurple;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          }
        }
      }

      .commandResponse {
        width: 100%;
        display: flex;
        justify-content: start;
        padding-left: 3px;

        .userQuestion {
          border-radius: 20px 20px 20px 0px;
          background: #fff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          width: 100%;
          // height: 153px;
          padding: 18px 25px 18px 25px;
          margin-top: 7px;
          margin-bottom: 7px;
          display: flex;
          flex-direction: column;
          /* justify-content: initial; */

          span {
            color: #2b2b2b;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &[data-theme="dark"] {
            background: #2f2f2f;

            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

            span {
              color: var(--bgtext);
            }
          }
        }
      }
    }

    .chatContent::-webkit-scrollbar {
      width: 10px;
    }

    .chatContent::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    .chatContent::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    [data-theme="dark"].chatContent::-webkit-scrollbar-track {
      background-color: #000;
      display: none;
    }
  }

  .userInput {
    width: 66%;
    // border: 1px solid red;
    // height: 100px;
    // height: 89px;
    height: 84px;
    padding-right: 275px;
    // padding-right: 86px;
    padding-top: 5px;
    margin-bottom: 25px;
    position: fixed;
    bottom: 0;
    // padding-bottom: 34px;

    .inputContent {
      width: 100%;
      height: 77px;
      display: flex;
      justify-content: space-between;
      // justify-content: center;
      align-items: center;
      border-radius: 50px;
      background: #fff;
      box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04),
        0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04),
        0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06),
        -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.1);
      padding-left: 30px;
      padding-right: 4px;

      .leftSide {
        width: 100%;
        display: flex;
        align-items: center;

        .filurl-div {
          .filename-div {
            position: absolute;
            top: -29px;
            border-radius: 50px;
            background: #f3f3f3;
            padding-inline: 20px;
            opacity: 0.3;
            border: 0.3px solid black;

            &[data-theme="dark"] {
              // background: #2f2f2f;
              border: 0.3px solid white;
              background: black;
              opacity: 0.9;
            }

            .filename {
              font-family: "Inter";
              font-size: 18px;

              &[data-theme="dark"] {
                color: white;
                // background: black;
              }

              //   background: white;
              //   color: white;
              //   opacity: 0.9;
              // }
              &[data-theme="dark"] {
                // color: white;
                // background: black;
              }
            }
          }
        }

        textarea {
          // border: none;
          // width: 100%;
          // height: 100%;
          // border: none;
          // padding-right: 20px;
          // outline: none;
          // padding-top: 20px;

          &::placeholder {
            color: #9d9d9d;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            border: none;
            outline: none;
            // padding-top: 10px;

            &[data-theme="dark"] {
              color: white;
            }
          }

          &:active {
            border: none;
            outline: none;
          }

          &[data-theme="dark"] {
            background-color: var(--background-color) !important;
            color: white;
          }
        }
      }

      .rightSide {
        // width: 100%;
        .rightside-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 66.281px;
          height: 66.281px;
          flex-shrink: 0;
          background: white;
          fill: #fff;
          filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
          border-radius: 50px;
          cursor: pointer;

          &[data-theme="dark"] {
            background: #2f2f2f;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
          }
        }
      }

      &[data-theme="dark"] {
        background: #000;
        box-shadow: 1px 1px 3px 2.697px rgba(255, 255, 255, 0.15) !important;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .newchat {
    width: 100%;
    padding-left: 75px;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    .newchat-content {
      width: 100%;
      margin-top: 47px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .chatTitle {
        width: 100%;

        .titleContent {
          // width: 381px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          // height: 120px;
          border-radius: 20px 20px 20px 0px;
          background: #fff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          padding: 18px 25px 25px 25px;

          h6 {
            color: #000;
            font-family: "Readex Pro";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }

          span {
            color: #2b2b2b;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      // .chatTitle-question {
      //     width: 100%;
      //     padding-right: 80px;

      //     .titleContent-question {
      //         width: 100%;
      //         // width: 381px;
      //         display: flex;
      //         margin-bottom: 10px;

      //         gap: 10px;
      //         // height: 120px;

      //         .left-question {
      //             border-radius: 20px 20px 20px 0px;
      //             background: #fff;
      //             box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      //             padding: 15px 15px 15px 15px;
      //             width: 50%;

      //             h6 {
      //                 color: #000;
      //                 font-family: "Readex Pro";
      //                 font-size: 16px;
      //                 font-style: normal;
      //                 font-weight: 400;
      //                 line-height: normal;
      //                 text-transform: capitalize;
      //             }
      //         }

      //     }
      // }
      .parent-divquestion {
        width: 100%;
        padding-right: 100px;

        .title-question {
          width: 100%;
        }
      }

      .chatContent {
        width: 100%;
        height: 60vh;
        // height: calc(100vh - 35.5vh - 39px);
        // height: 280px;
        padding-right: 75px !important;
        overflow-y: scroll;
        // padding-top: 81px;

        .userCommand {
          width: 100%;
          display: flex;
          justify-content: start;

          .userQuestion {
            border-radius: 25px 25px 25px 0px;
            background: #315191;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            // width: fit-content;
            max-width: 100%;
            line-break: anywhere;
            padding: 18px 25px 18px 25px;
            // width: 100%;
            // min-width: 94px;
            // width: auto;
            // width: 94px;
            // height: 55px;
            flex-shrink: 0;
            display: flex;
            justify-content: start;
            align-items: center;

            span {
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .commandResponse {
          width: 100%;
          display: flex;
          justify-content: start;

          .userQuestion {
            border-radius: 20px 20px 20px 0px;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            // width: 500px;
            width: 100%;
            // height: 153px;
            padding: 18px 25px 18px 25px;
            margin-top: 7px;

            span {
              color: #2b2b2b;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .userInput {
      // height: 5vh;
      width: 70%;
      // width: 100%;
      // position: absolute;
      // left: 0;
      // right: 0;
      bottom: 0;
      padding-right: 100px;
      padding-top: 1px;
      padding-bottom: 25px;
      padding-left: 20px;

      bottom: 0;

      // padding-bottom: 34px;

      .inputContent {
        width: 100%;
        height: 77px;
        display: flex;
        justify-content: space-between;
        // justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04),
          0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04),
          0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06),
          -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.1);
        padding-left: 30px;
        padding-right: 10px;

        .leftSide {
          width: 100%;
          display: flex;
          align-items: center;

          .filurl-div {
            .filename-div {
              position: absolute;
              top: -33px;

              .filename {
              }
            }
          }

          textarea {
            border: none;
            width: 100%;
            height: 100%;
            border: none;
            padding-right: 20px;
            outline: none;

            &::placeholder {
              color: #9d9d9d;
              font-family: "Inter";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              border: none;
              outline: none;
            }

            &:active {
              border: none;
              outline: none;
            }
          }
        }

        .rightSide {
          // width: 100%;
          .rightside-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 66.281px;
            height: 66.281px;
            flex-shrink: 0;
            background: white;
            fill: #fff;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
            border-radius: 50px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .newchat {
    width: 100%;
    padding-left: 20px;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .newchat-content {
      width: 100%;
      margin-top: 47px;

      .chatTitle {
        width: 100%;

        .titleContent {
          width: 381px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          // height: 120px;
          border-radius: 20px 20px 20px 0px;
          background: #fff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          padding: 18px 25px 25px 25px;

          h6 {
            color: #000;
            font-family: "Readex Pro";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }

          span {
            color: #2b2b2b;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      // .chatTitle-question {
      //     width: 100%;
      //     padding-right: 60px;

      //     .titleContent-question {
      //         width: 100%;
      //         // width: 381px;
      //         display: flex;
      //         margin-bottom: 10px;

      //         gap: 10px;
      //         // height: 120px;

      //         .left-question {
      //             border-radius: 20px 20px 20px 0px;
      //             background: #fff;
      //             box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      //             padding: 15px 15px 15px 15px;
      //             width: 50%;

      //             h6 {
      //                 color: #000;
      //                 font-family: "Readex Pro";
      //                 font-size: 16px;
      //                 font-style: normal;
      //                 font-weight: 400;
      //                 line-height: normal;
      //                 text-transform: capitalize;
      //             }
      //         }

      //     }
      // }
      .parent-divquestion {
        width: 100%;
        padding-right: 40px;

        .title-question {
          width: 100%;
        }
      }

      .chatContent {
        width: 100%;
        // height: 40vh;
        // height: calc(50vh - 10vh);
        // height: 280px;
        padding-right: 75px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // padding-top: 81px;

        .userCommand {
          width: 100%;
          display: flex;
          justify-content: start;

          .userQuestion {
            border-radius: 25px 25px 25px 0px;
            background: #315191;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            max-width: 100%;
            line-break: anywhere;
            padding: 18px 25px 18px 25px;
            // width: 100%;
            // min-width: 94px;
            // width: auto;
            // width: 94px;
            // height: 55px;
            flex-shrink: 0;
            display: flex;
            justify-content: start;
            align-items: center;

            span {
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .commandResponse {
          width: 100%;
          display: flex;
          justify-content: start;

          .userQuestion {
            border-radius: 20px 20px 20px 0px;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            // width: 500px;
            width: 100%;
            // height: 153px;
            padding: 18px 25px 18px 25px;
            margin-top: 7px;

            span {
              color: #2b2b2b;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .userInput {
      // height: 5vh;
      // width: 60%;
      // width: 100%;
      // position: absolute;
      // left: 0;
      // right: 0;
      bottom: 0;
      padding-right: 75px;
      padding-top: 0px;
      padding-bottom: 25px;
      margin-left: 40px;
      bottom: 0;

      // padding-bottom: 34px;

      .inputContent {
        width: 100%;
        height: 77px;
        display: flex;
        justify-content: space-between;
        // justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04),
          0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04),
          0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06),
          -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.1);
        padding-left: 30px;
        padding-right: 10px;

        .leftSide {
          width: 100%;
          display: flex;
          align-items: center;

          textarea {
            border: none;
            width: 100%;
            height: 100%;
            border: none;
            padding-right: 20px;
            outline: none;

            &::placeholder {
              color: #9d9d9d;
              font-family: "Inter";
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              border: none;
              outline: none;
            }

            &:active {
              border: none;
              outline: none;
            }
          }
        }

        .rightSide {
          // width: 100%;
          .rightside-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 66.281px;
            height: 66.281px;
            flex-shrink: 0;
            background: white;
            fill: #fff;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
            border-radius: 50px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .newchat {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    background: none;

    // background-size: 50%;
    // background-position: center;
    &[data-theme="dark"] {
      background: none;
    }

    .newchat-content {
      width: 100%;
      height: calc(100vh - 35vh);
      // margin-top: 0px;
      // margin-top: 100px;
      margin-top: 100px;
      // display: flex;
      // justify-content: start;

      .chatTitle {
        width: 100%;

        .titleContent {
          width: 100%;
          // width: 381px;
          display: flex;
          flex-direction: column;
          gap: 15px;
          // height: 120px;
          border-radius: 20px 20px 20px 0px;
          background: #fff;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
          padding: 18px 25px 25px 25px;

          h6 {
            color: #000;
            font-family: "Readex Pro";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }

          span {
            color: #2b2b2b;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }

      .parent-divquestion {
        width: 100%;
        padding-right: 0px;

        .title-question {
          width: 100%;

          .left-question {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;

            button {
              width: 100%;
              cursor: pointer;
              border-radius: 10px 10px 10px 10px;
              // background: #FFF;
              background: transparent;
              // box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04), 0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04), 0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06), -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.10);
              box-shadow: 0px 0px 3.248px 0px rgba(0, 0, 0, 0.15);

              // border: 1px solid #2B2B2B;
              border: none;
              padding: 10px;
              color: #2b2b2b;
              text-align: start;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              padding-left: 20px;

              &[data-theme="dark"] {
                background: #2b2b2b;
                // background: rebeccapurple;
                // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
                color: white;
              }
            }
          }
        }
      }

      .chatContent {
        width: 100%;
        // height: 100%;
        height: calc(100vh - 1vh - 50px);
        // height: 40%;
        // height: 400px;
        padding-right: 0px;
        overflow-y: scroll;
        margin: 20px;
        padding: 0px !important;
        margin-bottom: 50px;
        // padding-top: 81px;

        .userCommand {
          width: 100%;
          display: flex;
          justify-content: start;

          .userQuestion {
            border-radius: 25px 25px 25px 0px;
            // border-radius: 25px 25px 0px 25px;
            background: #315191;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            width: fit-content;
            padding: 18px 25px 18px 25px;
            // width: 100%;
            // min-width: 94px;
            // width: auto;
            // width: 94px;
            // height: 55px;
            flex-shrink: 0;
            display: flex;
            justify-content: start;
            align-items: center;

            span {
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }

        .commandResponse {
          width: 100%;
          display: flex;
          justify-content: start;
          // margin-right: 5px;
          padding-right: 10px;

          .userQuestion {
            border-radius: 20px 20px 20px 0px;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            width: 100%;
            padding: 18px 16px 18px 25px;
            margin-top: 7px;

            span {
              color: #2b2b2b;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .userInput {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      // position: fixed;

      // bottom: 0;
      padding-right: 3px;
      padding-top: 0px;
      padding-bottom: 20px;

      padding-left: 3px;
      margin-left: 30px;
      margin-right: 30px;
      padding-left: 10px;
      padding-right: 10px;

      &[data-theme="dark"] {
        background: black;
      }

      // padding-bottom: 34px;

      .inputContent {
        width: 100%;
        // height: 60px;
        display: flex;
        justify-content: space-between;
        // justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #fff;
        box-shadow: 0px 0px 1.061px 0px rgba(0, 0, 0, 0.04),
          0px 2.121px 6.364px 0px rgba(0, 0, 0, 0.04),
          0px 16.971px 25.456px 0px rgba(0, 0, 0, 0.06),
          -3.182px -3.182px 4.243px 0px rgba(0, 0, 0, 0.1);
        padding-left: 20px;
        padding-right: 3px;

        .leftSide {
          width: 100%;
          display: flex;
          align-items: center;

          .filurl-div {
            .filename-div {
              position: absolute;
              top: -38px;

              .filename {
              }
            }
          }

          textarea {
            border: none;
            width: 100%;
            height: 100%;
            border: none;
            padding-right: 20px;
            outline: none;

            &::placeholder {
              color: #9d9d9d;
              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;
              border: none;
              outline: none;
            }

            &:active {
              border: none;
              outline: none;
            }
          }
        }

        .rightSide {
          // width: 100%;
          .rightside-icon {
            display: fle;
            align-items: center;
            justify-content: center;
            width: 60.281px;
            height: 60.281px;
            flex-shrink: 0;
            background: white;
            fill: #fff;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.06));
            border-radius: 50px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
