.Blogs-main {
  height: auto;
  background: var(--secbackcolor);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  padding-bottom: 50px;

  .Blogs-title {
    text-align: center;
    font-family: "Readex Pro";

    h1 {
      color: var(--greycolor);
      font-size: 56.921px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;

      span {
        color: var(--bluecolor);
        font-family: "Readex Pro";
        font-size: 56.921px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    p {
      color: var(--greycolor);
      text-align: center;
      font-family: Inter;
      font-size: 23.827px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.953px;
    }
  }
  .Blogs-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 85%;
    gap: 30px;


      .Blog-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 637px;
        background: #fff;
        border-radius: 12.046px;
        box-shadow: 0px 7.409px 25.933px 3.705px rgba(0, 0, 0, 0.14);

        img {
          width: 100%;
          height: auto;
        }
        h6 {
          width: 95%;
          margin-left: 20px;
          color: var(--blackcolor);
          font-family: "Readex Pro";
          font-size: 35.319px;
          font-style: normal;
          font-weight: 600;
          line-height: 47.092px;
        }
        .para {
          width: 95%;
          margin-left: 20px;
          color: #000;
          font-family: Inter;
          font-size: 15.524px;
          font-style: normal;
          font-weight: 300;
          line-height: 26.859px; /* 145% */
          letter-spacing: 0.185px;
        }
        .published {
          text-align: start;
          margin-left: 20px;
          color: #555;
          font-family: "Century Gothic";
          font-size: 12.04px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.921px; /* 132.233% */
          letter-spacing: 2.654px;
          text-transform: uppercase;
        }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      border-color: none;
    }
  }
}
