.dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container-space {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: var(--background-color);
}

.dashboard .menuDiv {
  display: none;
  height: 10%;
  width: 10%;
}

.dashboard .left {
  width: 352px;
  border-radius: 50px;
  /* background: #fff; */
  /* background: var(--white); */
  background-color: var(--background-color);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
  margin: 15px 0px 15px 15px;
  height: calc(100dvh - 30px);
  overflow: hidden;
  filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.25));
  /* overflow-y: scroll; */
}
.dashboard .left::-webkit-scrollbar {
  /* display: none; */
}
.dashboard .menuIcon {
  width: 30px;
  margin-block: 5px;
}

.dashboard .heading {
  padding: 35px 38px 35px 35px;
  margin-bottom: 0px;
}
.dashboard .tab {
  padding: 18px 18px 18px 34px;
  cursor: pointer;
  list-style-type: none;
  margin: 0px 37px 0px 37px;
  color: #000 !important;
  background: var(--white);
  font-family: "Inter";
  font-size: 19.533px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  box-shadow: 0px 0px 3.244px 0px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  filter: drop-shadow(0px 0px 5.697px rgba(0, 0, 0, 0.15));
}

.activetab {
  background-color: var(--hanblue);
  color: var(--white) !important;
  font-family: "Inter";
  font-size: 19.463px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.tab.subHeading {
  background: var(--white);
  color: var(--black) !important;
}

/* Add the following CSS to set the active styles for each tab individually */
.tab.subHeading.activetab {
  background: var(--hanblue);
  color: var(--white) !important;
}

.dashboard .backBtn-div {
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  margin-top: 35px;
  margin-left: 0px;
  gap: 0px;
}

.dashboard .subHeadingLogout {
  color: var(--quartzz);
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  list-style-type: none;
}

.dashboard .logoutDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard .right {
  width: calc(100% - 367px);
  padding-right: 50px;
  /* padding-left: 245px; */
  height: 100vh;
  /* background: #ffffff; */
  /* height: 100%; */
  /* padding: 30px; */
  /* padding-top: 50px; */
  /* height: 97vh; */
  overflow-y: auto;
  background-image: url("../assets/images/Clip\ path\ group.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-image: url("../assets/images/saad.png");
 */
  &[data-theme="dark"] {
    /* background-image: url("../assets/images/ssad\ dark.png"); */
  }

  /* background-size: contain; */
  /* background-position: right;
  background-position-y: bottom; */
}
.right .rightHeader {
  width: 100%;
  padding-top: 19px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}
.profileSection {
  cursor: pointer;
  /* position: relative; */
}
.divimg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  width: 52px;
  height: 52px;
  background-color: #4176d4;
  border-radius: 50px;
}
.profileimg {
  width: 100%;
  height: 100%;
}

/* .divimg:hover .profileimg {
  transform: scale(1.4);
  transition: transform 0.7s ease;
} */

.leftTopbar {
  width: 100%;
  padding-left: 33px;
  padding-right: 44px;
  /* overflow: scroll; */
  height: 100%;
  background-color: var(--background-color);
}
.bottomscrolbar::-webkit-scrollbar {
  /* display: none; */
  width: 0.5px;
}
.bottomscrolbar::-webkit-scrollbar-track {
  background-color: var(--flashwhite);
}
.bottomscrolbar::-webkit-scrollbar-thumb {
  background-color: #c6c6c6f9;
  /* pending bg color  */
  border-radius: 5px;
}
.bottomscrolbar {
  width: 100%;
  overflow: scroll;
  height: 650px;
  padding: 10px 2px 350px 2px;
}
.leftTopbar .topbarTitle {
  width: 100%;
  padding: 0px 0px 30px 0px;
  border-bottom: 1px solid #c4c4c4;
}
.leftTopbar .topbarTitle2 {
  height: 75%;
  padding: 0px 0px 0px 0px;
  border-bottom: 1px solid #c4c4c4;
}
.topbarTitle .new-chat {
  width: 100%;
  color: var(--white);
  font-family: "Inter";
  font-size: 19.463px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  border-radius: 50px;
  background: var(--hanblue);
  text-align: left;
  padding-left: 34px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.chatsvg {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.5s ease; /* Combined transitions for opacity and transform */
  transform-origin: center; /* Set the transform origin to the center for rotation */
  position: absolute;
  right: 2%; /* Initial position off-screen to the right */
  /* bottom: 35%; */
}

.topbarTitle .new-chat:hover {
  background: var(
    --bg,
    linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
  );
}
.topbarTitle .new-chat:hover .chatsvg {
  opacity: 1;
  transform: translateX(-20px) rotate(360deg);
}
.titleContent {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.Pinned {
  padding-bottom: 15px;
  color: var(--bgtext);
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.chats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--black) !important;
  font-family: "Inter";
  font-size: 16.241px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--aliceblue) !important;
  height: 43.015px;
  width: 100%;
  border: none;
  border-radius: 50px;
  padding: 0px 25px 0px 25px;
}
.chatButton {
  width: 100%;
  height: 59.278px;
  background: var(--white);
  box-shadow: 0px 0px 3.248px 0px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 8px;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &[data-theme="dark"] {
    background-color: var(--background-color);
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.15);
  }
}

.chatButton:hover .leftdiv {
  width: 180px;
  height: 43px;
  display: block;
  padding: 8px 25px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  gap: 10px;

  /* flex: 2; */
}
.chatButton .leftdiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.leftdiv .withouttime {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .leftdiv .withouttime .givemes {
  width: 100%;
} */
.leftdiv .withtime .givemes {
  width: 100%;
  /* padding-inline: 10px; */
}

.leftdiv .withtime {
  width: 30%;
}
.chatButton:hover .giveme {
  margin-left: 10px;
}
.chatButton:hover .rightdiv {
  display: block;
  width: fit-content;
  background: #f0f1fd !important;
  height: 43.015px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  &[data-theme="dark"] {
    background: #2f2f2f !important;
  }
}
.chatButton .leftdiv {
  width: 100%;
  height: 43.015px;
  background-color: #f0f1fd;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: width 0.3s ease-in-out;
  &[data-theme="dark"] {
    background-color: #2f2f2f;
    color: var(--bgtext);
  }
}
.chatButton:hover .leftdiv .withtime {
  display: none;
}

.giveme {
  color: #000;
  font-family: "Inter";
  font-size: 16.241px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &[data-theme="dark"] {
    /* background-color: var(--background-color); */
    color: var(--bgtext);
  }
}
.givemes {
  color: #000;
  font-family: "Inter";
  font-size: 16.241px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  &[data-theme="dark"] {
    /* background-color: var(--background-color); */
    color: var(--bgtext);
  }
}
.rightdiv {
  flex: 1;
  padding: 12px;
  display: none;
  &[data-theme="dark"] {
    /* background: green !important; */
  }
}
.rightdiv:hover {
  display: block;
  width: 100%;
  &[data-theme="dark"] {
    /* background: hotpink !important; */
  }
}
.chatButton:hover .delete path {
  fill: red; /* Change the color to your desired color */
  &[data-theme="dark"] {
    fill: white !important;
  }
}
.line2 {
  margin-top: 25px;
  height: 1.091px;
  color: var(--cloud);
}
.today {
  color: var(--bgtext);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.lastchatbtn {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
}
.bottombarTitle {
  margin-bottom: 20px;
  border-top: 1px solid var(--cloud);
  width: 100%;
}
.bottombarTitle .new-chat {
  width: 100%;
  color: var(--black);
  font-family: "Inter";
  font-size: 19.463px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  border-radius: 50px;
  background: var(--white);
  fill: #fff;
  filter: drop-shadow(0px 0px 5.697px rgba(0, 0, 0, 0.15));
  &[data-theme="dark"] {
    background-color: var(--background-color) !important;
    color: var(--bgtext) !important;
    box-shadow: 1px 1px 6px 2.697px rgba(255, 255, 255, 0.15) !important;
  }
}
.themechangeP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px 32px 0px;
}
.themechange .light-theme {
  color: var(--Charlestongreen);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  &[data-theme="dark"] {
    color: var(--bgtext);
  }
}
.form-check-input:checked {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill=''/%3e%3c/svg%3e");
}
.form-check-input:focus {
  box-shadow: none;
  outline: none;
}
.togles {
  width: 100%;
  height: 100%;
}
.togglebtn .togle {
  height: 25px;
  width: 57px;
}
.bottomSetting-section {
  border-radius: 0px 0px 50px 50px;
  background: var(--white);
  width: 100%;
  padding: 0px 44px 0px 33px;
  position: fixed;
  z-index: 3;
  display: flex;
  left: 0px;
  bottom: 0px !important;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  &[data-theme="dark"] {
    background-color: var(--background-color);
  }
}
/* dropdown css here */
.profileSection .dropdownContent {
  width: 279px;
  /* height: 207px; */
  border-radius: 10px;
  background: var(--white);
  position: absolute;
  top: 80px;
  right: 50px;
  box-shadow: 0px 0px 1.905px 2px #b7b7b7;
  overflow: hidden;
  z-index: 44;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 69px;
  padding-left: 30px;
  padding-right: 30px;
  background: var(--white);
  &[data-theme="dark"] {
    background: #000;
    border-radius: 0px 0px 0px 0px !important;
    box-shadow: 2px 2px 6px 2.697px rgba(255, 255, 255, 0.15) !important;
  }
}
.topSection:hover {
  background: var(--f3f3f3);
  &[data-theme="dark"] {
    background: #181818;
  }
}
.topLeft .Personal-info {
  color: var(--black);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  &[data-theme="dark"] {
    color: white;
  }
}
/* &[data-theme="dark"] {
  color: white;
} */

/* dropdown css end */
/* personal modal css here */
.modalMainbody {
  padding: 0px;
}
.modalBody {
  width: 100%;
}
.modalBody .modalContent {
  width: 100%;
  padding: 39px 29px 39px 29px;
  &[data-theme="dark"] {
    background: #0d0d0d;
  }
}
.modalContent .modalTitle {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalTitle .profileCustomization {
  color: var(--black);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  &[data-theme="dark"] {
    color: white;
  }
}
.modalSubheading {
  width: 100%;
  margin-bottom: 25px;
}
.modalSubheading .belowFields {
  color: var(--black);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &[data-theme="dark"] {
    color: white;
  }
}
.modalFields {
  width: 100%;
  display: flex;
  margin-bottom: 30px;

  gap: 41px;
}
.leftside {
  width: 100%;
}
.nameInput {
  width: 100%;
  height: 53.414px;
  background-color: var(--f3f3f3);
  border: none;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 20px;
  color: var(--a5a5a5);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;

  &[data-theme="dark"] {
    background: #181818;
    color: white;
  }
}
.nameInput::placeholder {
  color: var(--a5a5a5);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
}
.occupationInput {
  width: 100%;
  height: 53.414px;
  background-color: var(--f3f3f3);
  border: none;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &[data-theme="dark"] {
    background: #181818;
    color: white;
  }
}
.occupationInput::placeholder {
  color: var(--a5a5a5);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 41px;
}
.modalline {
  display: flex;
  align-items: center;
  &[data-theme="dark"] {
    color: white;
  }
}
.modalline .leftsideline {
  width: 100%;
}
.middlesidetext {
  margin-inline: 10px;
}
.middlesidetext .And {
  /* color variable name pending */
  color: #8d8d8d;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modalBottom {
  width: 100%;
  &[data-theme="dark"] {
    color: white;
  }
}
.bottomTitle {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
}
.bottommQuestion {
  color: var(--black);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &[data-theme="dark"] {
    color: white;
  }
}
.profilemodal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
}
.profilemodal-btn .saveButton {
  border-radius: 7.741px;
  background: var(
    --gradient,
    linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
  );
  width: 129px;
  /* height: 45px; */
  padding: 11.612px 23.224px;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
.profilemodal-btn .cancelButton {
  border-radius: 7.741px;
  background: var(
    --gradient,
    linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
  );
  width: 129px;
  /* height: 45px; */
  padding: 11.612px 23.224px;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
/* personal modal css end  here */

/* delete modal css  start here  */
.secondmaodalBody {
  padding: 0px;
  &[data-theme="dark"] {
    background: #0d0d0d;
  }
}
.secondmodal {
  width: 100%;
}
.secondmodalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-block: 25px;
  padding-inline: 40px;
}
.secondmodalContent .deleteAccount {
  color: var(--black);
  font-family: "Readex Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  &[data-theme="dark"] {
    color: white;
  }
}
.secondmodalContent .confirmdelete {
  color: var(--black);
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &[data-theme="dark"] {
    color: white;
  }
}
.secondmodalFotter {
  display: flex;
  width: 100%;
  border-top: 2px solid #e4e5f1;
  &[data-theme="dark"] {
    border-top: 2px solid #000;
  }
}
.secondmodalFotter .footerLeftside {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 80px 20px 80px;
  border-left: 2px solid #e4e5f1;
  &[data-theme="dark"] {
    border-left: 2px solid #000;
  }
}
.footerLeftside .cancel {
  color: #497aef;
  color: var(--aef);
  font-family: "Readex Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.footerLeftside .delete {
  color: var(--red);
  font-family: "Readex Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.hamburger {
  display: none;
}
/* term modal start */
.checkbox-termsmodal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
.checkbox-termsmodal .form-check {
  display: flex;
  gap: 10px;
}
.checkbox-termsmodal .form-check a {
  text-decoration: none;
  color: #676767;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.formchecklabel {
  color: #676767;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #0d6efd;
  /* border-color: #f3f3f3; */
}

.terms-modal {
  width: 100%;
}
.termsmodal-header {
  border: none;
}
.terms-header {
  width: 100%;
  text-align: center;
}
.terms-header span {
  color: #6c6c76;
  text-align: center;
  font-family: "Readex Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 57.6px */
}
.termsmodal-body {
  padding-left: 79px;
  padding-right: 79px;
}
.middle-body {
  margin-bottom: 30px;
  margin-top: 30px;
  height: 550px;
  overflow-y: auto;
}
.middle-body .middle-top h5 {
  color: #676767;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 28.8px */
}
.middle-body .middle-top h6 {
  color: #676767;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}
.middle-body .middle-top p {
  color: #676767;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.term-botton {
  padding-bottom: 38px;
  width: 100%;
  padding-left: 79px;
  padding-right: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
}
.term-botton .agree {
  width: 129px;
  height: 45px;
  padding: 11.612px 23.224px;
  border-radius: 7.741px;
  background: var(
    --gradient,
    linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
  );
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
.term-botton .disagree {
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid var(--gradient, #4276d4);
  color: #314f90;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background: white;
}
/* delete modal css  end here  */
@media screen and (max-width: 1600px) {
  .dashboard .heading {
    padding: 25px 38px 25px 35px;
  }
  .bottomscrolbar {
    padding: 10px 2px 500px 2px;
  }
  .leftTopbar .topbarTitle {
    padding: 0px 0px 20px 0px;
  }

  .dashboard .right {
    padding-right: 10px;
  }
  .Pinned {
    font-size: 22px;
  }
  .themechangeP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 15px 0px;
  }
  .modalMainbody {
    padding: 0px;
  }
  .modalBody {
    width: 100%;
  }
  .modalBody .modalContent {
    width: 100%;
    padding: 20px 29px 20px 29px;
  }
  .modalContent .modalTitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .modalTitle .profileCustomization {
    color: var(--black);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .modalSubheading {
    width: 100%;
    margin-bottom: 10px;
  }
  .modalSubheading .belowFields {
    color: var(--black);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .modalFields {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    gap: 20px;
  }
  .leftside {
    width: 100%;
  }
  .nameInput {
    width: 100%;
    height: 53.414px;
    background-color: var(--f3f3f3);
    border: none;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 10px;
  }
  .nameInput::placeholder {
    color: var(--a5a5a5);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }
  .occupationInput {
    width: 100%;
    height: 53.414px;
    background-color: var(--f3f3f3);
    border: none;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .occupationInput::placeholder {
    color: var(--a5a5a5);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }
  .modalline {
    display: flex;
    align-items: center;
  }
  .modalline .leftsideline {
    width: 100%;
  }
  .middlesidetext {
    margin-inline: 10px;
  }
  .middlesidetext .And {
    color: var(--dd);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .modalBottom {
    width: 100%;
  }
  .bottomTitle {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .bottommQuestion {
    color: var(--black);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* terms modal css start here */
  .terms-modal {
    width: 100%;
  }
  .termsmodal-header {
    border: none;
  }
  .terms-header {
    width: 100%;
    text-align: center;
  }
  .terms-header span {
    color: #6c6c76;
    text-align: center;
    font-family: "Readex Pro";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 57.6px */
  }
  .termsmodal-body {
    padding-left: 30px;
    padding-right: 30px;
  }
  .middle-body {
    margin-bottom: 20px;
    margin-top: 10px;
    height: 350px;
    overflow-y: auto;
  }
  .middle-body .middle-top h5 {
    color: #676767;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 28.8px */
  }
  .middle-body .middle-top h6 {
    color: #676767;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
  }
  .middle-body .middle-top p {
    color: #676767;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }
  .term-botton {
    padding-bottom: 10px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
  }
  .term-botton .agree {
    width: 129px;
    height: 45px;
    padding: 11.612px 23.224px;
    border-radius: 7.741px;
    background: var(
      --gradient,
      linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
    );
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
  }
  .term-botton .disagree {
    height: 45px;
    padding: 11.612px 23.224px;
    border-radius: 8px;
    border: 1px solid var(--gradient, #4276d4);
    color: #314f90;
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    background: white;
  }
  .profilemodal-btn .saveButton {
    border-radius: 7.741px;
    background: var(
      --gradient,
      linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
    );
    width: 129px;
    /* height: 45px; */
    padding: 8.612px 13.224px;
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
  }
  .profilemodal-btn .cancelButton {
    border-radius: 7.741px;
    background: var(
      --gradient,
      linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
    );
    width: 129px;
    /* height: 45px; */
    padding: 8.612px 13.224px;
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
  }
}
@media screen and (max-width: 800px) {
  .hamburger {
    display: block;
  }
  .dashboard {
    display: flex;
    flex-direction: column;
  }
  .dashboard .left {
    z-index: 2;
    width: 80%;
    padding-left: 0px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
  }
  .dashboard .headingBack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    background: white;
    &[data-theme="dark"] {
      background: #000;
    }
  }

  .dashboard .menuDiv {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px 0;
  }
  .dashboard .left .backBtn-div {
    display: block;
    margin-right: 20px;
  }

  .dashboard .right {
    width: 100%;
    height: fit-content;
    padding: 20px 0;
    background: var(--white);
    overflow-y: auto;
  }
  .headingBack .heading {
    padding: 35px 10px 35px 10px;
    margin-bottom: 0px;
  }
  .heading img {
    width: 200px;
  }
  .backBtn-div .backicon {
    width: 40px !important;
    height: 40px !important;
  }
}
@media screen and (max-width: 600px) {
  .right .rightHeader {
    width: 100%;
    padding-top: 0px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
  }
  .dashboard .right {
    /* border: 3px solid green; */
    width: 100%;
    height: calc(100vh - 100px);
    /* height: 85vh; */
    /* height: 100vh; */
    /* height: fit-content; */
    padding: 0px 0;

    background: var(--white);
    overflow-y: auto;
    &[data-theme="dark"] {
      background: black;
    }
  }
  .dashboard .left {
    border-radius: 0px;
    margin: 0px;
    /* height: 100%; */
    width: 100%;
    background: white;
    height: fit-content;
  }
  .leftTopbar {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
    height: 100%;
  }
  .leftTopbar .topbarTitle {
    padding-bottom: 10px;
  }

  .topbarTitle .new-chat {
    /* width: 274.909px; */
    width: 100%;
  }
  .bottombarTitle {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: none !important;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  .bottomSetting-section {
    border-top: 1px solid var(--cloud);
    border-radius: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .lastchatbtn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
  }
  .today {
    margin-bottom: 7px;
    font-size: 16px;
  }
  .Pinned {
    padding-bottom: 7px;
    font-size: 18px;
  }
  .line2 {
    margin-top: 10px;
  }

  /* modal css here  */
  .modalMainbody {
    padding: 0px;
  }
  .modalBody {
    width: 100%;
  }
  .modalBody .modalContent {
    width: 100%;
    padding: 20px 10px 20px 10px;
  }
  .modalContent .modalTitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .modalTitle .profileCustomization {
    color: var(--black);
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .modalSubheading {
    width: 100%;
    margin-bottom: 10px;
  }
  .modalSubheading .belowFields {
    color: var(--black);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .modalFields {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px !important;

    gap: 20px;
  }
  .leftside {
    width: 100%;
  }
  .nameInput {
    width: 100%;
    height: 53.414px;
    background-color: var(--f3f3f3);
    border: none;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 10px;
  }
  .nameInput::placeholder {
    color: var(--a5a5a5);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }
  .occupationInput {
    width: 100%;
    height: 53.414px;
    background-color: var(--f3f3f3);
    border: none;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .occupationInput::placeholder {
    color: var(--a5a5a5);
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }
  .modalline {
    display: flex;
    align-items: center;
  }
  .modalline .leftsideline {
    width: 100%;
  }
  .middlesidetext {
    margin-inline: 10px;
  }
  .middlesidetext .And {
    color: var(--dd);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .modalBottom {
    width: 100%;
  }
  .bottomTitle {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bottommQuestion {
    color: var(--black);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* first modal css end here */
  /* delete modal css start here */
  .secondmaodalBody {
    padding: 0px;
  }
  .secondmodal {
    width: 100%;
  }
  .secondmodalContent {
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-block: 25px;
    /* padding-inline: 25px; */
  }
  .secondmodalContent .deleteAccount {
    color: var(--black);
    font-family: "Readex Pro";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
  .secondmodalContent .confirmdelete {
    color: var(--black);
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .secondmodalFotter {
    display: flex;

    width: 100%;
    border-top: 2px solid #e4e5f1;
  }
  .secondmodalFotter .footerLeftside {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 80px 20px 80px;
    border-left: 2px solid #e4e5f1;
  }
  .footerLeftside .cancel {
    color: var(--aef);
    font-family: "Readex Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
  .footerLeftside .delete {
    color: var(--red);
    font-family: "Readex Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
  /* terms modal css start here */

  .terms-modal {
    width: 100%;
    /* height: 100%; */
  }
  .termsmodal-header {
    border: none;
  }
  .terms-header {
    width: 100%;
    text-align: center;
  }
  .terms-header span {
    color: #6c6c76;
    text-align: center;
    font-family: "Readex Pro";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 57.6px */
  }
  .termsmodal-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .middle-body {
    margin-bottom: 0px;
    margin-top: 10px;
    height: 350px;
    overflow-y: auto;
  }
  .middle-body .middle-top h5 {
    color: #676767;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 100%;  */
    margin-bottom: 20px;
  }
  .middle-body .middle-top h6 {
    color: #676767;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-bottom: 10px;
  }
  .middle-body .middle-top p {
    color: #676767;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .term-botton {
    padding-bottom: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .term-botton .agree {
    width: fit-content;
    /* width: 100%; */
    /* width: 129px; */
    height: 45px;
    padding: 11.612px 23.224px;
    border-radius: 7.741px;
    background: var(
      --gradient,
      linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
    );
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
  }
  .term-botton .disagree {
    width: fit-content;
    height: 45px;
    padding: 11.612px 23.224px;
    border-radius: 8px;
    border: 1px solid var(--gradient, #4276d4);
    color: #314f90;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    background: white;
  }
}
.trans-100 {
  transform: translateX(-800px);
  transition: 0.1s ease-in-out;
}
.trans-0 {
  transform: translateX(0px);
  transition: 0.1s ease-in-out;
}

/* Second Modal */
.cookies-modal {
  .cookies-modal-body {
    p {
      color: #676767;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
    .term-botton {
      button {
        width: 100%;
        height: 45px;
        padding: 11.612px 23.224px;
        border-radius: 7.741px;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        color: #fff;
        text-align: center;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
      }
    }
  }
}
