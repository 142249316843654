.settings {
  width: 100%;

  .settingsContent {
    width: 100%;
    padding: 50px 0px 50px 0px;

    .setingHeader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .settinghContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .toggle-container {
          // width: 100%;
          // width: 100%;
          width: fit-content;
          // width: 732px;
          // width: 932px;
          height: 70px;
          border-radius: 44.907px;
          background: #3f70c8;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
          padding-left: 10px;
          padding-right: 10px;

          .toggle-button {
            border-radius: 53.889px;
            background: #3f70c8;
            // background-color: pink; /* Default background color */
            color: #f8fdff;
            /* Default text color */
            // width: 284px;
            height: 58px;
            border: none;
            /* Remove default button border */
            font-family: "Inter";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            transition: 0.7s, color 0.7s;
            padding-left: 20px;
            padding-right: 20px;
            // margin-right: 20px;
            // margin-left: 20px;

            &.active {
              background: #f8fdff;
              color: #3f70c8;
              font-family: "Inter";
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;

              &[data-theme="dark"] {
                background-color: black;
                color: #fff;
              }
            }

            &:hover {
              // background: #f8fdff;
              // color: #3f70c8;
            }
          }
        }

        .span-container {
          // width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .settings {
    width: 100%;

    .settingsContent {
      width: 100%;
      padding: 50px 0px 50px 0px;

      .setingHeader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .settinghContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          .toggle-container {
            // width: 100%;
            // width: 100%;
            width: fit-content;
            // width: 732px;
            // width: 932px;
            height: 70px;
            border-radius: 44.907px;
            background: #3f70c8;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding-left: 10px;
            padding-right: 10px;

            .span-container {
              // width: 100%;
              // display: flex;
              // flex-direction: column;
              // justify-content: center;
              // align-items: center;

              .toggle-button {
                border-radius: 53.889px;
                background: #3f70c8;
                // background-color: pink; /* Default background color */
                color: #f8fdff;
                /* Default text color */
                // width: 284px;
                height: 58px;
                border: none;
                /* Remove default button border */
                font-family: "Inter";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                transition: 0.7s, color 0.7s;
                padding-left: 20px;
                padding-right: 20px;
                // margin-right: 20px;
                // margin-left: 20px;

                &.active {
                  background: #f8fdff;
                  color: #3f70c8;
                  font-family: "Inter";
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  text-transform: capitalize;
                }

                &:hover {
                  // background: #f8fdff;
                  // color: #3f70c8;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .settings {
    width: 100%;

    .settingsContent {
      width: 100%;
      padding: 50px 0px 50px 0px;

      .setingHeader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .settinghContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-inline: 5px;

          .toggle-container {
            // width: 100%;
            // width: 100%;
            width: fit-content;
            // width: 732px;
            // width: 932px;
            height: 40px;
            border-radius: 44.907px;
            background: #3f70c8;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0px;
            padding-left: 5px;
            padding-right: 5px;

            // .span-container {
            // width: 100%;

            .toggle-button {
              border-radius: 53.889px;
              background: #3f70c8;
              // background-color: pink; /* Default background color */
              color: #f8fdff;
              /* Default text color */
              // width: 284px;
              height: 28px !important;
              border: none;
              /* Remove default button border */
              font-family: "Inter";
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              transition: 0.7s, color 0.7s;
              padding-left: 5px !important;
              padding-right: 5px !important;
              // margin-right: 20px;
              // margin-left: 20px;

              &.active {
                background: #f8fdff;
                color: #3f70c8;
                font-family: "Inter";
                font-size: 13px !important;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                height: 20px;
              }

              &:hover {
                // background: #f8fdff;
                // color: #3f70c8;
              }
            }

            // }
          }
        }
      }
    }
  }
}
