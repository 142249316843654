.plans-sections {
  //   background: var(--secbackcolor);
  padding: 0px 100px 0px 100px;
  h3 {
    text-align: center;
    padding-bottom: 20px;
  }
  .top {
    max-width: 1400px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .para {
      text-align: center;
    }
  }

  .plans-cards {
    // padding: 130px 0px 100px 0px;
    max-width: 1506px;
    display: flex;
    // align-items: center;
    column-gap: 18px;
    background: url(/assets/images/plansback.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .started {
      transition: all 0.3s linear;
      width: 90%;

      &:hover {
        background: var(
          --gradient,
          linear-gradient(92deg, #2f4d8a -15.2%, #4276d4 102.24%)
        );
        transition: all 0.3s linear;
        box-shadow: 0px 0px 10px 0px rgb(24, 24, 24);
      }
    }

    .ist,
    .third {
      // min-height: 523px;
      // height: 451px;
      // min-height: 451px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      border-radius: 46.667px;
      border: 2.667px solid #f3f3f3;
      background: #fefefc;

      &[data-theme="dark"] {
        background-color: var(--background-color);
        border: 1.871px solid #181818;
        box-shadow: 0px 0px 15px 0px rgba(255, 251, 251, 0.3);
      }

      .price {
        margin-bottom: 31px;
      }

      .started {
        margin-bottom: 72px;
      }

      .lists {
        display: flex;
        flex-direction: column;
        row-gap: 15.33px;
        // padding-right: 42px;
      }

      .list {
        display: flex;
        align-items: center;
        column-gap: 13.33px;

        &[data-theme="dark"] {
          p {
            color: #8e8e8e;
          }
        }
      }
    }

    .second {
      // min-height: 523px;
      // min-height: 746.667px;
      border-radius: 46.667px;
      background: #fefefc;
      box-shadow: 0px 0px 22.667px 0px #b7b7b7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      &[data-theme="dark"] {
        background-color: var(--background-color);
        box-shadow: 0px 0px 15.905px 0px #b7b7b7;
      }

      .popular {
        position: absolute;
        top: 0;
        right: 0;
      }

      .div {
        position: absolute;
        top: 0;
        right: 10px;
        background: #e6e6e6;
        padding-inline: 10px;
        border-radius: 20px;

        span {
          color: #676767;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 180%;
        }
      }

      .price {
        margin-bottom: 55.79px;
      }

      .started {
        margin-bottom: 97.91px;
      }

      .lists {
        display: flex;
        flex-direction: column;
        row-gap: 19px;
        // padding-right: 30px;
        // padding-right: 57px;
      }

      .list {
        display: flex;
        align-items: center;
        column-gap: 15.5px;
      }
    }

    .ist,
    .second,
    .third {
      padding: 43px 14px 66px 14px;
      // padding: 37px;
      // padding: 21px;
      // width: 100%;
      width: 323px;
      // min-height: 850px;
      p {
        margin-bottom: 0px;
      }

      .started {
        cursor: pointer;
        border-radius: 13.333px;
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        padding: 10.67px 42.67px;
        border: none;
        color: #fefefc;
        text-align: center;
        font-family: Arial;
        font-size: 14.97px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.97px;

        /* 100% */
        // color: #fefefc;
        // text-align: center;
        // font-family: "Inter";
        // font-size: 21.333px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: 100%;
        // margin-bottom: 69px;
        &[data-theme="dark"] {
          color: #0f0f0f;
        }
      }

      .started:hover {
        cursor: pointer;

        /* 100% */
        // color: #fefefc;
        // text-align: center;
        // font-family: "Inter";
        // font-size: 21.333px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: 100%;
        // margin-bottom: 69px;
      }

      .price {
        border-radius: 24px;
        background: #f3f3f3;
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // overflow: hidden;

        h3 {
          // font-weight: 400 !important;
          color: #6c6c76;
          text-align: center;
          // font-family: "Josefin Sans";
          font-size: 36.188px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
        }

        &[data-theme="dark"] {
          background: #181818;
        }
      }

      p {
        // color: var(--lightgreycolor);
        // font-family: "Inter";
        // font-size: 22.667px;
        // font-style: normal;
        // font-weight: 400;
        // line-height: normal;
        // letter-spacing: 0.907px;
        // text-transform: capitalize;
        color: #8e8e8e;

        font-family: Arial;
        font-size: 15.905px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.636px;
        text-transform: capitalize;
      }

      .plan-type {
        margin-bottom: 20px;
        color: #8e8e8e;
        text-align: center;
        font-family: "Inter";
        font-size: 15.905px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.636px;
        text-transform: capitalize;

        &[data-theme="dark"] {
          // color: #8E8E8E;
        }
      }
    }
  }

  &.darkMode {
    p,
    h3,
    button {
      color: #fff !important;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .plans-sections {
    // background: var(--secbackcolor);
    padding: 20px 10px 0px 10px;

    .top {
      max-width: 1400px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .para {
        text-align: center;
      }
    }

    .plans-cards {
      // padding: 130px 0px 100px 0px;
      max-width: 1506px;
      display: flex;
      column-gap: 5px;
      background: url(/assets/images/plansback.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      // flex-wrap: wrap;
      justify-content: center;
      height: 100%;

      .started {
        transition: all 0.3s linear;
        width: 90%;

        &:hover {
          background: var(
            --gradient,
            linear-gradient(92deg, #2f4d8a -15.2%, #4276d4 102.24%)
          );
          transition: all 0.3s linear;
          box-shadow: 0px 0px 10px 0px rgb(24, 24, 24);
        }
      }

      .ist,
      .third {
        min-height: 300px !important;
        // height: 451px;
        // min-height: 451px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        border-radius: 46.667px;
        border: 2.667px solid #f3f3f3;
        background: #fefefc;

        .price {
          margin-bottom: 31px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          // padding-right: 42px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 13.33px;
        }
      }

      .second {
        min-height: 350px;
        // min-height: 746.667px;
        border-radius: 46.667px;
        background: #fefefc;
        box-shadow: 0px 0px 22.667px 0px #b7b7b7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // padding: 15px 14px 35px 14px !important;

        .popular {
          position: absolute;
          top: 0;
          right: 0;
        }

        .price {
          margin-bottom: 20.79px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 19px;
          // padding-right: 30px;
          // padding-right: 57px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 15.5px;
        }
      }

      .ist,
      .second,
      .third {
        // padding: 15px 14px 35px 14px !important;
        // padding: 37px;
        // padding: 21px;
        // width: 100%;
        width: 250px;
        padding-bottom: 35px;

        p {
          margin-bottom: 0px;
        }

        .started {
          cursor: pointer;
          border-radius: 13.333px;
          background: var(
            --gradient,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
          padding: 10.67px 42.67px;
          border: none;
          color: #fefefc;
          text-align: center;
          font-family: Arial;
          font-size: 14.97px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.97px;
          /* 100% */
          // color: #fefefc;
          // text-align: center;
          // font-family: "Inter";
          // font-size: 21.333px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: 100%;
          // margin-bottom: 69px;
        }

        .price {
          border-radius: 24px;
          background: #f3f3f3;
          padding: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // overflow: hidden;

          h3 {
            // font-weight: 400 !important;
            color: #6c6c76;
            text-align: center;
            // font-family: "Josefin Sans";
            font-size: 26.188px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        p {
          // color: var(--lightgreycolor);
          // font-family: "Inter";
          // font-size: 22.667px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: normal;
          // letter-spacing: 0.907px;
          // text-transform: capitalize;
          color: #8e8e8e;

          font-family: Arial;
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }

        .plan-type {
          margin-bottom: 10px;
          color: #8e8e8e;
          text-align: center;
          font-family: "Inter";
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .plans-sections {
    // background: var(--secbackcolor);
    padding: 20px 10px 0px 10px;

    .top {
      max-width: 1400px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .para {
        text-align: center;
      }
    }

    .plans-cards {
      // padding: 130px 0px 100px 0px;
      max-width: 1506px;
      display: flex;
      column-gap: 5px;
      background: url(/assets/images/plansback.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      // flex-wrap: wrap;
      justify-content: center;
      height: 100%;

      .started {
        transition: all 0.3s linear;
        width: 90%;

        &:hover {
          background: var(
            --gradient,
            linear-gradient(92deg, #2f4d8a -15.2%, #4276d4 102.24%)
          );
          transition: all 0.3s linear;
          box-shadow: 0px 0px 10px 0px rgb(24, 24, 24);
        }
      }

      .ist,
      .third {
        min-height: 300px !important;
        // height: 451px;
        // min-height: 451px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        border-radius: 46.667px;
        border: 2.667px solid #f3f3f3;
        background: #fefefc;
        padding: 43px 5px 66px 5px;

        .price {
          margin-bottom: 31px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          // padding-right: 42px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 13.33px;
        }
      }

      .second {
        min-height: 350px;
        // min-height: 746.667px;
        border-radius: 46.667px;
        background: #fefefc;
        box-shadow: 0px 0px 22.667px 0px #b7b7b7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // padding: 15px 14px 35px 14px !important;

        .popular {
          position: absolute;
          top: 0;
          right: 0;
        }

        .price {
          margin-bottom: 20.79px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 19px;
          // padding-right: 30px;
          // padding-right: 57px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 15.5px;
        }
      }

      .ist,
      .second,
      .third {
        // padding: 15px 14px 35px 14px !important;
        // padding: 37px;
        // padding: 21px;
        // width: 100%;
        width: 100%;
        // width: 250px;
        padding-bottom: 35px;

        p {
          margin-bottom: 0px;
        }

        .started {
          cursor: pointer;
          border-radius: 13.333px;
          background: var(
            --gradient,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
          padding: 10.67px 42.67px;
          border: none;
          color: #fefefc;
          text-align: center;
          font-family: Arial;
          font-size: 14.97px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.97px;
          /* 100% */
          // color: #fefefc;
          // text-align: center;
          // font-family: "Inter";
          // font-size: 21.333px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: 100%;
          // margin-bottom: 69px;
        }

        .price {
          border-radius: 24px;
          background: #f3f3f3;
          padding: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // overflow: hidden;

          h3 {
            // font-weight: 400 !important;
            color: #6c6c76;
            text-align: center;
            // font-family: "Josefin Sans";
            font-size: 26.188px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        p {
          // color: var(--lightgreycolor);
          // font-family: "Inter";
          // font-size: 22.667px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: normal;
          // letter-spacing: 0.907px;
          // text-transform: capitalize;
          color: #8e8e8e;

          font-family: Arial;
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }

        .plan-type {
          margin-bottom: 10px;
          color: #8e8e8e;
          text-align: center;
          font-family: "Inter";
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .plans-sections {
    // background: var(--secbackcolor);
    padding: 20px 10px 0px 10px;

    .top {
      max-width: 1400px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .para {
        text-align: center;
      }
    }

    .plans-cards {
      // padding: 130px 0px 100px 0px;
      max-width: 1506px;
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 30px;
      background: url(/assets/images/plansback.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      // flex-wrap: wrap;
      justify-content: center;
      height: 100%;

      .started {
        transition: all 0.3s linear;
        width: 90%;

        &:hover {
          background: var(
            --gradient,
            linear-gradient(92deg, #2f4d8a -15.2%, #4276d4 102.24%)
          );
          transition: all 0.3s linear;
          box-shadow: 0px 0px 10px 0px rgb(24, 24, 24);
        }
      }

      .ist,
      .third {
        min-height: 300px !important;
        // height: 451px;
        // min-height: 451px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        border-radius: 46.667px;
        border: 2.667px solid #f3f3f3;
        background: #fefefc;
        padding: 43px 5px 66px 5px;

        .price {
          margin-bottom: 31px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          // padding-right: 42px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 13.33px;
        }
      }

      .second {
        min-height: 350px;
        // min-height: 746.667px;
        border-radius: 46.667px;
        background: #fefefc;
        box-shadow: 0px 0px 22.667px 0px #b7b7b7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        // padding: 15px 14px 35px 14px !important;

        .popular {
          position: absolute;
          top: 0;
          right: 0;
        }

        .price {
          margin-bottom: 20.79px;
        }

        .started {
          margin-bottom: 20px;
        }

        .lists {
          display: flex;
          flex-direction: column;
          row-gap: 19px;
          // padding-right: 30px;
          // padding-right: 57px;
        }

        .list {
          display: flex;
          align-items: center;
          column-gap: 15.5px;
        }
      }

      .ist,
      .second,
      .third {
        // padding: 15px 14px 35px 14px !important;
        // padding: 37px;
        // padding: 21px;
        // width: 100%;
        width: 250px;
        padding-bottom: 35px;
        margin-bottom: 20px;

        p {
          margin-bottom: 0px;
        }

        .started {
          cursor: pointer;
          border-radius: 13.333px;
          background: var(
            --gradient,
            linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
          );
          padding: 10.67px 42.67px;
          border: none;
          color: #fefefc;
          text-align: center;
          font-family: Arial;
          font-size: 14.97px;
          font-style: normal;
          font-weight: 400;
          line-height: 14.97px;
          /* 100% */
          // color: #fefefc;
          // text-align: center;
          // font-family: "Inter";
          // font-size: 21.333px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: 100%;
          // margin-bottom: 69px;
        }

        .price {
          border-radius: 24px;
          background: #f3f3f3;
          padding: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // overflow: hidden;

          h3 {
            // font-weight: 400 !important;
            color: #6c6c76;
            text-align: center;
            // font-family: "Josefin Sans";
            font-size: 26.188px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        p {
          // color: var(--lightgreycolor);
          // font-family: "Inter";
          // font-size: 22.667px;
          // font-style: normal;
          // font-weight: 400;
          // line-height: normal;
          // letter-spacing: 0.907px;
          // text-transform: capitalize;
          color: #8e8e8e;

          font-family: Arial;
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }

        .plan-type {
          margin-bottom: 10px;
          color: #8e8e8e;
          text-align: center;
          font-family: "Inter";
          font-size: 13.905px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.636px;
          text-transform: capitalize;
        }
      }
    }
  }
  .plan-1,
  .plan-2 {
    margin-top: 20px !important;
  }
}
