.cards-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  background: var(--secbackcolor);

  .middle-sec-h1 {
    margin-bottom: 80px;

    h1 {
      color: var(--greycolor);
      text-align: center;
      font-family: "Readex Pro";
      font-size: 84.058px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -3.362px;
      text-transform: uppercase;
      margin: 0px;

      span {
        background: linear-gradient(92deg, #33539e -15.2%, #2f4d8a 102.24%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p {
      color: #454545;
      text-align: center;
      font-family: Inter;
      font-size: 26.667px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 1.067px;
    }
  }

  .benefits-cards {
    max-width: 80%;
    display: flex;
    justify-content: center;
    row-gap: 40px;
    column-gap: 54px;
    position: relative;

    .benefitsimg {
      position: absolute;
      left: 0%;
      right: 0%;
      top: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .benefit-card {
      position: relative;
      z-index: 2;
      width: 440px;
      border-radius: 13.333px;
      background: var(--whitecolor);
      box-shadow: -2.667px 5.333px 21.333px 0px rgba(0, 0, 0, 0.25);
      padding: 42.667px 21.333px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 18.67px;
      transition: all 0.3s linear;
      &:hover {
        transition: all 0.3s linear;

        box-shadow: -2.667px 5.333px 50px 0px rgba(0, 0, 0, 0.25);
      }
      img {
        width: 124px;
        height: 108px;
        object-fit: contain;
      }
      h6 {
        color: var(--darkgreycolor);

        font-family: "Readex Pro";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.333%;
        text-transform: capitalize;
        text-align: center;
      }
      p {
        color: #474747;
        text-align: center;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.075px;
      }
    }
  }
  .negotiate-sec {
    background: var(--secbackcolor);
    width: 85%;
    .neg-card {
      border-radius: 24px;
      background: var(--whitecolor);
      margin: 0px auto;
      display: flex;
      max-width: 1333.333px;
      padding: 86px 21.333px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 92px;
      box-shadow: 0px 0px 22.667px 0px #b7b7b7;

      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        h2 {
          text-transform: capitalize;
          color: var(--bluecolor);
        }
        .para-large {
          max-width: 810px;
          text-align: center;
          line-height: 140%;
        }
      }
      .explore-btn {
        background: var(
          --gradient,
          linear-gradient(92deg, #4276d4 -15.2%, #2f4d8a 102.24%)
        );
        padding: 12.637px 50.547px;
        border: none;
        border-radius: 12.637px;
        color: var(--whitecolor);
        cursor: pointer;
        transition: all 0.3s linear;
        color: var(--whitecolor);
        font-family: "Inter";
        font-size: 26.667px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        &:hover {
          background: var(
            --gradient,
            linear-gradient(92deg, #2f4d8a -15.2%, #4276d4 102.24%)
          );
          transition: all 0.3s linear;
          box-shadow: 0px 0px 10px 0px rgb(24, 24, 24);
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .negotiate-sec {
      .neg-card {
        gap: 60px;
        .explore-btn {
          font-size: 20px;
        }
        .top {
          .para-large {
            font-size: 24px;
            line-height: 120%;
          }
          h2 {
            font-size: 45px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .negotiate-sec {
      .neg-card {
        gap: 60px;
        .top {
          .para-large {
            font-size: 20px;
            line-height: 120%;
          }
          h2 {
            font-size: 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .negotiate-sec {
      padding: 20px;
      .neg-card {
        gap: 30px;
        padding: 50px 20px;
        .top {
          gap: 10px;
        }
        .explore-btn {
          padding: 10px 20px;
        }
      }
    }
  }
}
@media screen and (max-width:1500px){
  .cards-main{
    height: auto;
  }
}
