.advice-card {
    .card-content {
        max-width: 468px;


        .card-headers {

            img {
                width: 100%;
            }
        }

        .card-title {
            margin-top: 24px;

            h5 {
                color: #2B2B2B;

                font-family: "Readex Pro";
                font-size: 1.667vw;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -1.28px;
            }
        }

        .card-subtitle {
            margin-top: 24px;

            p {
                color: #707070;
                font-family: "Inter";
                font-size: 1.146vw;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.88px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .advice-card {
        .card-content {
            width: 100% !important;
            max-width: 600px !important;


            .card-headers {

                img {
                    width: 100%;
                }
            }

            .card-title {
                margin-top: 10px;
                text-align: center;

                h5 {
                    color: #2B2B2B;

                    font-family: "Readex Pro";
                    font-size: 18px !important;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -1.28px;
                }
            }

            .card-subtitle {
                margin-top: 10px;
                text-align: center;

                p {
                    color: #707070;
                    font-family: "Inter";
                    font-size: 16px !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.88px;
                }
            }
        }
    }
}