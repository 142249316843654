.background-account {
    background: white;
    width: 100%;
    height: 100%;

    &[data-theme="dark"] {
        background: #0d0d0d;
    }

    .signupPage {
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/images/OBJECTS.svg");
        background-repeat: no-repeat;
        background-size: cover;
        // background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);
        display: flex;
        justify-content: center;
        align-items: center;

        &[data-theme="dark"] {
            // height: 100vh;
            background-image: url("../../assets/images/blackss.svg");
            background-repeat: no-repeat;
            background-size: cover;

        }


        .signin-content {
            width: 100%;

            .signup-logo {
                position: absolute;
                // width: 100%;
                // height: 100%;

                top: 48px;
                padding-left: 133px;

                .custom {

                    // width: 220px;
                    // height: 61.21px;
                }
            }

            .signup-card {
                width: 100%;

                .signupcard-content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 100px 0px 69px 0px;
                    // height: 100%;
                    // padding: 79px 450px 80px 450px;
                    // display: flex;
                    // justify-content: center;

                    // align-items: center;
                    .main-card {
                        width: 539px;
                        // width: 100%;
                        // height: 100%;
                        // width: 539px;
                        // height: 741px;
                        flex-shrink: 0;
                        border-radius: 40px;
                        background: var(--white);
                        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
                        // padding: 50px 47px 110px 47px;
                        padding: 43px 47px 43px 47px;

                        &[data-theme="dark"] {
                            background: black;
                            box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.20);

                        }

                        .cardtitle {
                            display: flex;
                            justify-content: space-between;

                            .leftside {
                                h6 {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 52px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                    &[data-theme="dark"] {
                                        color: white;

                                    }

                                }


                            }


                        }


                        .card-field {
                            .firstfield {
                                // padding-bottom: 18px;

                                .label {
                                    width: 100%;
                                    padding-bottom: 18px;

                                    label {
                                        color: #000;
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;

                                        &[data-theme="dark"] {
                                            color: white;
                                        }

                                    }
                                }

                                .inputfield {
                                    width: 100%;
                                    position: relative;
                                    padding-bottom: 18px;
                                    // display: flex;


                                    input {
                                        width: 100%;
                                        height: 57px;
                                        border-radius: 9px;
                                        border: 1px solid #ADADAD;
                                        background: var(--white);
                                        padding: 19px 50px 19px 19px;

                                        &[data-theme="dark"] {
                                            color: white;
                                            background: #000;
                                        }

                                        &::placeholder {
                                            // padding: 19px 25px 19px 19px;
                                            color: #808080;
                                            font-family: "Inter";
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }


                                        }

                                        &:focus {
                                            border-radius: 9px;
                                            // outline: #4285F4;
                                            border: 1px solid #4285F4 !important;
                                            background: var(--white);

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }
                                        }


                                    }

                                    .eye-icon {
                                        position: absolute;
                                        top: 37%;
                                        right: 10px;
                                        transform: translateY(-50%);
                                        cursor: pointer;
                                    }
                                }


                            }

                            .firstfield-signup {
                                .label {
                                    width: 100%;
                                    padding-bottom: 18px;
                                    padding-top: 44px;

                                    label {
                                        color: #000;
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;

                                        &[data-theme="dark"] {
                                            color: white;
                                        }


                                    }
                                }

                                .inputfield {
                                    width: 100%;
                                    padding-bottom: 17px;

                                    input {
                                        width: 100%;
                                        height: 57px;
                                        border-radius: 9px;
                                        border: 1px solid #ADADAD;
                                        background: var(--white);
                                        padding: 19px 25px 19px 19px;

                                        &[data-theme="dark"] {
                                            color: white;
                                            background: #000;
                                        }

                                        &::placeholder {
                                            // padding: 19px 25px 19px 19px;
                                            color: #9D9D9D;
                                            font-family: "Inter";
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }


                                        }

                                        &:focus {
                                            border-radius: 9px;
                                            // outline: #4285F4;
                                            border: 1px solid #4285F4 !important;
                                            background: var(--white);

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }
                                        }
                                    }
                                }


                            }
                        }

                        .card-bottom {
                            width: 100%;

                            .bottom-title {
                                // padding-top: 46px;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                // padding-top: 20px;

                                p {
                                    color: #8D8D8D;
                                    font-family: "Inter";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                    span {
                                        color: #3E6EC5;
                                        font-family: "Inter";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        cursor: pointer;

                                        &[data-theme="dark"] {
                                            color: #527CCB;
                                        }
                                    }
                                }

                            }

                            .signin-btn {
                                width: 100%;
                                padding-top: 25px;

                                button {
                                    width: 100%;
                                    height: 54px;
                                    border-radius: 10px;
                                    background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                    color: #FFF;
                                    font-family: "Inter";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    border: none;

                                    &[data-theme="dark"] {
                                        color: #0D0D0D;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .background-account {
        .signupPage {
            width: 100%;
            height: 100vh;
            background-image: url("../../assets/images/OBJECTS.svg");
            background-repeat: no-repeat;
            background-size: cover;
            // background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

            .signin-content {
                width: 100%;

                .signup-logo {
                    position: absolute;
                    // width: 100%;
                    // height: 100%;

                    top: 10px;
                    padding-left: 100px;

                    .custom {

                        // width: 220px;
                        // height: 61.21px;
                    }
                }

                .signup-card {
                    width: 100%;

                    .signupcard-content {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 35px 0px 0px 0px;

                        .main-card {
                            width: 539px;

                            flex-shrink: 0;
                            border-radius: 40px;
                            background: var(--white);
                            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
                            // padding: 10px 20px 20px 20px;
                            padding: 20px 20px 30px 20px;

                            .cardtitle {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .leftside {
                                    padding-bottom: 5px;

                                    h6 {
                                        color: var(--black);
                                        font-family: Arial;
                                        font-size: 40px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }

                                }


                            }

                            .card-field {
                                .firstfield {


                                    .label {
                                        width: 100%;
                                        padding-bottom: 10px;


                                        label {
                                            color: var(--black);
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        padding-bottom: 10px;

                                        input {
                                            width: 100%;
                                            height: 47px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 12px 50px 12px 19px;

                                            &::placeholder {
                                                // padding: 19px 25px 19px 19px;
                                                color: (--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;
                                                // outline: #4285F4;
                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }

                                        .eye-icon {
                                            position: absolute;
                                            top: 42%;
                                            right: 10px;
                                            transform: translateY(-50%);
                                            cursor: pointer;
                                        }
                                    }


                                }

                                .firstfield-signup {
                                    .label {

                                        width: 100%;
                                        padding-bottom: 10px;
                                        padding-top: 10px;

                                        label {
                                            color: var(--black);
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        padding-bottom: 10px;

                                        input {
                                            width: 100%;
                                            height: 47px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 12px 25px 12px 19px;

                                            &::placeholder {
                                                // padding: 19px 25px 19px 19px;
                                                color: var(--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;
                                                // outline: #4285F4;
                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }
                                    }


                                }
                            }

                            .card-bottom {
                                width: 100%;

                                .bottom-title {
                                    padding-top: 15px;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;



                                }

                                .signin-btn {
                                    width: 100%;
                                    padding-top: 0px;

                                    button {
                                        width: 100%;
                                        height: 54px;
                                        border-radius: 10px;
                                        background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                        box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                        color: var(--white);
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .background-account {
        height: unset;

        .signupPage {
            width: 100%;
            // height: 100%;
            padding: 10px 0px 50px 0px;
            // height: 100vh;
            // background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

            .signin-content {
                width: 100%;
                padding: 0px 10px 0px 10px;

                .signup-logo {
                    position: absolute;
                    // width: 100%;
                    // height: 100%;

                    top: 18px;
                    padding-left: 10px;

                    .custom {

                        // width: 220px;
                        // height: 61.21px;
                    }
                }

                .signup-card {
                    width: 100%;


                    .signupcard-content {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // padding: 100px 10px 0px 10px;
                        margin-top: 100px;
                        margin-bottom: 50px;
                        // margin-bottom: 50px;


                        // align-items: center;
                        .main-card {
                            width: 100%;
                            // width: 539px;
                            // width: 100%;

                            flex-shrink: 0;
                            border-radius: 40px;
                            background: var(--white);
                            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
                            // padding: 50px 47px 110px 47px;
                            padding: 20px 10px;
                            // margin-left: 20px;
                            // margin-right: 20px;

                            .cardtitle {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .leftside {
                                    h6 {
                                        color: var(--black);
                                        font-family: Arial;
                                        font-size: 25px !important;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }

                                }


                            }



                            .card-field {
                                .firstfield {
                                    .label {
                                        width: 100%;
                                        padding-bottom: 10px;

                                        label {
                                            color: var(--black);
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        padding-bottom: 18px;

                                        input {
                                            width: 100%;
                                            height: unset;
                                            padding: 10px 20px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);


                                            &::placeholder {
                                                // padding: 19px 25px 19px 19px;
                                                color: (--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;
                                                // outline: #4285F4;
                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }

                                        .eye-icon {

                                            top: 35%;

                                        }
                                    }




                                }

                                .firstfield-signup {
                                    .label {
                                        width: 100%;
                                        padding-bottom: 10px;
                                        padding-top: 30px;

                                        label {
                                            color: var(--black);
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        padding-bottom: 18px;

                                        input {
                                            width: 100%;
                                            height: unset;
                                            padding: 10px 20px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 10px 20px;

                                            &::placeholder {
                                                // padding: 19px 25px 19px 19px;
                                                color: var(--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;
                                                // outline: #4285F4;
                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }
                                    }


                                }
                            }

                            .card-bottom {
                                width: 100%;

                                .bottom-title {
                                    padding-top: 10px;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;

                                    span {
                                        color: var(--4285F4);
                                        font-family: Arial;
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        cursor: pointer;
                                    }

                                }

                                .signin-btn {
                                    width: 100%;
                                    padding-top: 10px;

                                    button {
                                        width: 100%;
                                        height: unset;
                                        padding: 10px 20px;
                                        border-radius: 10px;
                                        background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                        box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                        color: var(--white);
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}