.frequently-section {
  width: 100%;
  background-image: url("../../assets/images/frequently-bg.png");
  background-size: cover;
  min-height: 691px;
  position: relative;
  margin-top: 5%;
  padding: 0 100px;
  .frequently-content {
    padding-top: 73px;
    padding-bottom: 73px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    z-index: 2;
    width: 80%;
    margin: auto;

    .right {
      width: 100%;
      position: relative;
      z-index: 4;
      .accordian-main {
        width: 100%;

        .accordian-item {
          margin-top: 24px;
          border-radius: 12px;

          .accordian-header {
            background: white;
            color: #2b2b2b;
            font-family: "Readex Pro";
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 42.667px;
            border-radius: 12px;
            overflow: hidden;

            /* 193.939% */
            .accordion-button {
              font-family: "Readex Pro";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: 42.667px;
            }

            .accordion-button:not(.collapsed) {
              color: #2b2b2b;
              background: white;
              box-shadow: none;
              overflow: hidden;
              border-radius: 12px;
              font-family: "Readex Pro";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: 42.667px;

              // box-shadow: inset 0 -1px 0 rgba(217, 96, 96, 0.125);
            }

            .accordion-button:not(.collapsed)::after {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
              transform: rotate(-180deg);
            }
          }

          .accordian-body {
            padding: 18px 27px;

            .body-content {
              p {
                color: #707070;

                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.72px;
              }
            }
          }
        }
      }
    }
  }

  .leftimg {
    position: absolute;
    // left: 0;
    right: 0;
    padding-top: 90px;
    bottom: 91px;
    opacity: 0.5;
  }

  .rightimg {
    position: absolute;
    // right: 0;
    left: 0;
    padding-top: 90px;
    bottom: 91px;
    opacity: 0.5;
  }
}

// @media only screen and (max-width: 1600px) {
//   .frequently-section {
//     width: 100%;
//     background-image: url("../../assets/images/frequently-bg.png");
//     height: 691px;
//     position: relative;

//     .frequently-content {
//       padding-right: 40px;
//       padding-left: 40px;
//       padding-top: 73px;
//       padding-bottom: 73px;
//       height: 100%;
//       display: flex;
//       position: absolute;
//       z-index: 2;
//       width: 100%;

//       .left {
//         width: 40%;
//         display: flex;
//         // justify-content: center;
//         align-items: center;

//         .title {
//           max-width: 489px;

//           h5 {
//             color: #fff;
//             font-family: "Readex Pro";
//             font-size: 2.5vw;
//             font-style: normal;
//             font-weight: 500;
//             line-height: normal;
//             letter-spacing: -1.92px;
//           }

//           p {
//             color: #fff;

//             font-family: "Inter";
//             font-size: 18px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: normal;
//             letter-spacing: -0.72px;
//           }
//         }
//       }

//       .right {
//         width: 60%;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         .accordian-main {
//           width: 100%;

//           .accordian-item {
//             margin-top: 10px;
//             border-radius: 12px;

//             .accordian-header {
//               background: white;
//               color: #2b2b2b;

//               font-family: "Readex Pro";
//               font-size: 22px;
//               font-style: normal;
//               font-weight: 500;
//               line-height: 42.667px;
//               border-radius: 12px;
//               overflow: hidden;

//               /* 193.939% */

//               .accordion-button:not(.collapsed) {
//                 color: #2b2b2b;
//                 background: white;
//                 box-shadow: none;
//                 overflow: hidden;
//                 border-radius: 12px;

//                 // box-shadow: inset 0 -1px 0 rgba(217, 96, 96, 0.125);
//               }

//               .accordion-button:not(.collapsed)::after {
//                 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
//                 transform: rotate(-180deg);
//               }
//             }

//             .accordian-body {
//               padding: 10px 17px;

//               .body-content {
//                 p {
//                   color: #707070;

//                   font-family: "Inter";
//                   font-size: 18px;
//                   font-style: normal;
//                   font-weight: 400;
//                   line-height: normal;
//                   letter-spacing: -0.72px;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .leftimg {
//       position: absolute;
//       // left: 0;
//       right: 0;
//       padding-top: 90px;
//       bottom: 91px;
//       opacity: 0.5;
//     }

//     .rightimg {
//       position: absolute;
//       // right: 0;
//       left: 0;
//       padding-top: 90px;
//       bottom: 91px;
//       opacity: 0.5;
//     }
//   }
// }

// @media only screen and (max-width: 1300px) {
//     .frequently-section {
//         width: 100%;
//         background-image: url("../../assets/images/frequently-bg.png");
//         height: 691px;
//         position: relative;

//         .frequently-content {
//             padding-right: 40px;
//             padding-left: 40px;
//             padding-top: 73px;
//             padding-bottom: 73px;
//             height: 100%;
//             display: flex;
//             position: absolute;
//             z-index: 2;
//             width: 100%;

//             .left {
//                 width: 40%;
//                 display: flex;
//                 // justify-content: center;
//                 align-items: center;

//                 .title {
//                     max-width: 489px;

//                     h5 {
//                         color: #FFF;
//                         font-family: "Readex Pro";
//                         font-size: 2.5vw;
//                         font-style: normal;
//                         font-weight: 500;
//                         line-height: normal;
//                         letter-spacing: -1.92px;
//                     }

//                     p {
//                         color: #FFF;

//                         font-family: "Inter";
//                         font-size: 18px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: normal;
//                         letter-spacing: -0.72px;
//                     }
//                 }
//             }

//             .right {
//                 width: 60%;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;

//                 .accordian-main {
//                     width: 100%;

//                     .accordian-item {
//                         margin-top: 10px;
//                         border-radius: 12px;

//                         .accordian-header {
//                             background: white;
//                             color: #2B2B2B;

//                             font-family: "Readex Pro";
//                             font-size: 22px;
//                             font-style: normal;
//                             font-weight: 500;
//                             line-height: 42.667px;
//                             border-radius: 12px;
//                             overflow: hidden;

//                             /* 193.939% */

//                             .accordion-button:not(.collapsed) {
//                                 color: #2B2B2B;
//                                 background: white;
//                                 box-shadow: none;
//                                 overflow: hidden;
//                                 border-radius: 12px;

//                                 // box-shadow: inset 0 -1px 0 rgba(217, 96, 96, 0.125);

//                             }

//                             .accordion-button:not(.collapsed)::after {
//                                 background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
//                                 transform: rotate(-180deg);
//                             }

//                         }

//                         .accordian-body {
//                             padding: 10px 17px;

//                             .body-content {
//                                 p {
//                                     color: #707070;

//                                     font-family: "Inter";
//                                     font-size: 18px;
//                                     font-style: normal;
//                                     font-weight: 400;
//                                     line-height: normal;
//                                     letter-spacing: -0.72px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         .leftimg {
//             position: absolute;
//             // left: 0;
//             right: 0;
//             padding-top: 90px;
//             bottom: 91px;
//             opacity: 0.5;

//         }

//         .rightimg {
//             position: absolute;
//             // right: 0;
//             left: 0;
//             padding-top: 90px;
//             bottom: 91px;
//             opacity: 0.5;
//         }
//     }
// }
// @media only screen and (max-width: 600px) {
//   .frequently-section {
//     width: 100%;
//     // height: 100%;

//     background-image: url("../../assets/images/frequently-bg.png");
//     // height: 691px;
//     height: 600px;

//     position: relative;
//     // object-fit: contain;
//     // overflow: hidden;

//     .frequently-content {
//       padding-right: 10px;
//       padding-left: 10px;
//       // padding-top: 50px;
//       // padding-bottom: 50px;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       position: absolute;
//       z-index: 2;
//       width: 100%;

//       .left {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         .title {
//           max-width: 489px;
//           text-align: center;

//           h5 {
//             color: #fff;
//             font-family: "Readex Pro";
//             font-size: 25px !important;
//             font-style: normal;
//             font-weight: 500;
//             line-height: normal;
//             letter-spacing: -1.92px;
//             margin-bottom: 10px;
//           }

//           p {
//             color: #fff;

//             font-family: "Inter";
//             font-size: 16px;
//             font-style: normal;
//             font-weight: 400;
//             line-height: normal;
//             letter-spacing: -0.72px;
//           }
//         }
//       }

//       .right {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         .accordian-main {
//           width: 100%;

//           .accordian-item {
//             margin-top: 10px;
//             border-radius: 12px;

//             .accordian-header {
//               background: white;
//               color: #2b2b2b;

//               font-family: "Readex Pro";
//               font-size: 20px !important;
//               font-style: normal;
//               font-weight: 500;
//               line-height: 42.667px;
//               border-radius: 12px;
//               overflow: hidden;
//               // height: 50px;

//               /* 193.939% */
//               .accordion-button {
//                 height: 50px;

//                 .accordion-button:not(.collapsed) {
//                   color: #2b2b2b;
//                   background: white;
//                   box-shadow: none;
//                   overflow: hidden;
//                   border-radius: 12px;

//                   // box-shadow: inset 0 -1px 0 rgba(217, 96, 96, 0.125);
//                 }

//                 .accordion-button:not(.collapsed)::after {
//                   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
//                   transform: rotate(-180deg);
//                 }
//               }
//             }

//             .accordian-body {
//               padding: 10px 17px;

//               .body-content {
//                 p {
//                   color: #707070;

//                   font-family: "Inter";
//                   font-size: 16px !important;
//                   font-style: normal;
//                   font-weight: 400;
//                   line-height: normal;
//                   letter-spacing: -0.72px;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//     .leftimg {
//       display: none;
//     }

//     .rightimg {
//       display: none;
//     }
//   }
// }
