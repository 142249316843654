.language {
    width: 100%;

    .languageContent {
        width: 100%;
        padding: 100px 0px 0px 0px;

        .mainlanguage {
            box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));


            .languageContainer {
                // fill: #FFF;
                // box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));
                // filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));
                width: 472.512px;
                // height: 632.593px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                &[data-theme="dark"] {

                    background: #2F2F2F;
                }

                .leftside {
                    padding: 15px 53px 15px 53px;
                    display: flex;
                    align-items: center;
                    // align-self: center;
                    // align-items: center;
                    // justify-content: center;
                    border-bottom: 1px solid #E4E5F1;

                    &[data-theme="dark"] {

                        border: none;
                    }

                    span {
                        color: #000;
                        font-family: "Inter";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                    }



                    .flagmark {
                        padding-left: 20px;
                    }

                    &[data-theme="dark"] {
                        span {

                            color: white;
                        }
                    }
                }

                &:hover {
                    // background-color: #F8FDFF;
                    background-color: #F3F3F3;

                    &[data-theme="dark"] {
                        span {

                            color: black;
                        }
                    }
                }


            }

            .selectedLanguage {


                // background-color: red;
            }

            .blurLanguage {
                filter: blur(1px);

                opacity: 0.7;

                &:hover {
                    background-color: #F3F3F3;
                    filter: none;
                    opacity: 1;
                }

            }

            &[data-theme="dark"] {
                background-color: aqua !important;
                // background: #2F2F2F;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .language {
        width: 100%;

        .languageContent {
            width: 100%;
            padding: 40px 0px 0px 0px;

            .mainlanguage {
                box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .languageContainer {
                    // fill: #FFF;
                    // box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));
                    // filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));
                    width: 472.512px;
                    // height: 632.593px;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .leftside {
                        padding: 10px 25px 10px 53px;
                        display: flex;
                        align-items: center;
                        // align-self: center;
                        // align-items: center;
                        // justify-content: center;
                        border-bottom: 1px solid #E4E5F1;

                        img {
                            width: 50px;
                            height: 20px;
                        }

                        span {
                            color: #000;
                            font-family: "Inter";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .flagmark {
                            padding-left: 20px;

                        }
                    }

                    &:hover {
                        background-color: #F8FDFF;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .language {
        width: 100%;

        .languageContent {
            width: 100%;
            padding: 40px 0px 0px 0px;

            .mainlanguage {
                box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .languageContainer {
                    // fill: #FFF;
                    // box-shadow: (0px 0px 7px rgba(0, 0, 0, 0.15));
                    // filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));
                    width: 300.512px;
                    // height: 632.593px;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .leftside {
                        padding: 10px 9px 10px 18px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #E4E5F1;

                        img {
                            width: 3\50px;
                            height: 20px;
                        }

                        span {
                            color: #000;
                            font-family: "Inter";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: capitalize;
                        }

                        .flagmark {
                            padding-left: 20px;

                        }
                    }

                    &:hover {
                        background-color: #F8FDFF;
                    }
                }
            }
        }
    }
}