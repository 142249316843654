.advicecard-section {
  .section-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .vedio {
      width: 100%;
      display: flex;
      justify-content: center;

      video {
        width: 100%;
        height: 100%;
        max-width: 1300px;
      }
    }
  }

  h5 {
    color: #2b2b2b;
    font-family: "Readex Pro";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2.44px;
    text-align: center;
  }

  p {
    color: #707070;
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .advicecard-section {
    h5 {
      font-size: 22px !important;
      letter-spacing: -0.14px;
    }

    .advicecard-p {
      font-size: 18px !important;
    }

    .inner-cardsection {
      padding-left: 10px;
      padding-right: 10px;
    }

    .section-content {
      padding: 10px 10px 0px 10px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  }
}
