.background-account {
    background: white;
    width: 100%;
    height: 100%;

    &[data-theme="dark"] {
        background: #0d0d0d;
    }

    .forgetemail {
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/images/OBJECTS.svg");
        background-repeat: no-repeat;
        background-size: cover;

        // background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);
        &[data-theme="dark"] {
            // height: 100vh;
            background-image: url("../../assets/images/blackss.svg");
            background-repeat: no-repeat;
            background-size: cover;

        }

        .forgetemail-content {
            width: 100%;
            height: 100vh;

            .signup-logo {
                position: absolute;
                // width: 100%;
                // height: 100%;

                top: 56px;
                padding-left: 133px;

                .custom {

                    // width: 220px;
                    // height: 61.21px;
                }
            }

            .signup-card {
                width: 100%;
                // border: 1px solid red;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;

                .signupcard-content {
                    width: 100%;
                    height: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 0px 0px 0px;
                    // padding: 169px 0px 0px 0px;
                    // border: 1px solid red;

                    .main-card {
                        width: 539px;

                        flex-shrink: 0;
                        border-radius: 40px;
                        background: var(--white);
                        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

                        padding: 50px 47px 80px 47px;

                        &[data-theme="dark"] {

                            background: black;
                            box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.20);


                        }

                        .cardtitle {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .leftside {
                                text-align: center;

                                h6 {
                                    color: #000;
                                    font-family: Arial;
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                    &[data-theme="dark"] {
                                        color: white;
                                    }
                                }

                            }

                            .rightside {
                                text-align: center;


                                h6 {
                                    color: #8D8D8D;
                                    text-align: center;
                                    font-family: "Inter";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                    &[data-theme="dark"] {
                                        color: white;
                                    }


                                }
                            }
                        }

                        .accountRecovery-middle {
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            // gap: 22px;
                            padding: 60px 0px 0px 0px;

                            .firstfield {
                                margin-bottom: 21px;

                                .label {
                                    width: 100%;
                                    padding-bottom: 19px;

                                    label {
                                        color: #000;
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;

                                        &[data-theme="dark"] {
                                            color: white;
                                        }
                                    }
                                }

                                .inputfield {
                                    width: 100%;
                                    // padding-bottom: 18px;
                                    position: relative;

                                    input {
                                        width: 100%;
                                        height: 57px;
                                        border-radius: 9px;
                                        border: 1px solid #ADADAD;
                                        background: var(--white);
                                        padding: 19px 50px 19px 19px;

                                        &[data-theme="dark"] {
                                            color: white;
                                            background: #000;
                                        }

                                        &::placeholder {

                                            color: #808080;
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal;

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }


                                        }

                                        &:focus {
                                            border-radius: 9px;

                                            border: 1px solid #4285F4 !important;
                                            background: var(--white);

                                            &[data-theme="dark"] {
                                                color: white;
                                                background: #000;
                                            }
                                        }
                                    }

                                    .eye-icon {
                                        position: absolute;
                                        top: 50%;
                                        right: 10px;
                                        transform: translateY(-50%);
                                        cursor: pointer;
                                    }
                                }


                            }


                        }




                        .card-bottom {
                            width: 100%;



                            .signin-btn {
                                width: 100%;
                                padding-top: 43px;

                                button {
                                    width: 100%;
                                    height: 54px;
                                    border-radius: 10px;
                                    background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                    box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                    // border-radius: 10px;
                                    // background: var(--text-primary);
                                    // box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                    color: #FFF;
                                    font-family: "Inter";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    border: none;

                                    &[data-theme="dark"] {
                                        color: black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .background-account {
        .forgetemail {
            width: 100%;
            // height: 100vh;
            background-image: url("../../assets/images/OBJECTS.svg");
            background-repeat: no-repeat;
            background-size: cover;
            // background: linear-gradient(86deg, #0D4D80 1.8%, rgba(0, 162, 214, 0.51) 99.57%);

            .forgetemail-content {
                width: 100%;


                .signup-logo {
                    position: absolute;
                    top: 28px;
                    padding-left: 100px;

                    .custom {}
                }

                .signup-card {
                    width: 100%;


                    .signupcard-content {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // padding: 100px 0px 0px 0px;

                        .main-card {
                            width: 539px;

                            flex-shrink: 0;
                            border-radius: 40px;
                            background: var(--white);
                            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

                            padding: 50px 47px 50px 47px;


                            .cardtitle {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .leftside {
                                    text-align: center;

                                    h6 {
                                        color: #000;
                                        font-family: Arial;
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }

                                }

                                .rightside {
                                    text-align: center;

                                    h6 {
                                        color: #8D8D8D;

                                        font-family: "Inter";
                                        font-size: 13px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;


                                    }
                                }
                            }

                            .accountRecovery-middle {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                // gap: 5px;
                                padding: 30px 0px 0px 0px;

                                .firstfield {
                                    // margin-bottom: 21px;

                                    .label {
                                        width: 100%;
                                        padding-bottom: 12px;

                                        label {
                                            color: #000;
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        // padding-bottom: 18px;

                                        input {
                                            width: 100%;
                                            height: 57px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 19px 50px 19px 19px;

                                            &::placeholder {

                                                color: var(--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;

                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }

                                        .eye-icon {
                                            position: absolute;
                                            top: 47%;
                                            right: 10px;
                                            transform: translateY(-50%);
                                            cursor: pointer;
                                        }
                                    }

                                    .inputfield2 {
                                        width: 100%;
                                        // padding-bottom: 18px;

                                        input {
                                            width: 100%;
                                            height: 57px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 19px 50px 19px 19px;

                                            &::placeholder {

                                                color: var(--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;

                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }

                                        .eye-icon {
                                            position: absolute;
                                            top: 47%;
                                            right: 10px;
                                            transform: translateY(-50%);
                                            cursor: pointer;
                                        }
                                    }


                                }
                            }




                            .card-bottom {
                                width: 100%;



                                .signin-btn {
                                    width: 100%;
                                    padding-top: 25px;

                                    button {
                                        width: 100%;
                                        height: 54px;
                                        border-radius: 10px;
                                        background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                        box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                        color: var(--white);
                                        font-family: Arial;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .background-account {
        .forgetemail {
            width: 100%;

            padding: 10px 10px 50px 10px;

            .forgetemail-content {
                width: 100%;

                .signup-logo {
                    position: absolute;
                    padding-left: 10px;


                    top: 10px;

                    .custom {}
                }

                .signup-card {
                    width: 100%;
                    height: unset;

                    .signupcard-content {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 100px 0px 0px 0px;

                        .main-card {
                            // width: 539px;
                            width: 100%;

                            flex-shrink: 0;
                            border-radius: 40px;
                            background: var(--white);
                            box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

                            padding: 40px 10px 50px 10px;

                            .cardtitle {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .leftside {
                                    text-align: center;

                                    h6 {
                                        color: var(--black);
                                        font-family: Arial;
                                        font-size: 24px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                    }

                                }

                                .rightside {
                                    text-align: center;


                                    h6 {

                                        font-size: 16px !important;



                                    }
                                }
                            }

                            .accountRecovery-middle {
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                gap: 0px;
                                padding: 30px 0px 0px 0px;

                                .firstfield {
                                    .label {
                                        width: 100%;
                                        padding-bottom: 19px;

                                        label {
                                            color: var(--black);
                                            font-family: "Inter";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                        }
                                    }

                                    .inputfield {
                                        width: 100%;
                                        // padding-bottom: 18px;

                                        input {
                                            width: 100%;
                                            height: 57px;
                                            border-radius: 9px;
                                            border: 1px solid #ADADAD;
                                            background: var(--white);
                                            padding: 19px 50px 19px 19px;

                                            &::placeholder {

                                                color: var(--808080);
                                                font-family: "Inter";
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;


                                            }

                                            &:focus {
                                                border-radius: 9px;

                                                border: 1px solid #4285F4 !important;
                                                background: var(--white);
                                            }
                                        }


                                    }


                                }


                            }




                            .card-bottom {
                                width: 100%;



                                .signin-btn {
                                    width: 100%;
                                    padding-top: 25px;

                                    button {
                                        width: 100%;
                                        height: 54px;
                                        border-radius: 10px;
                                        background: var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%));
                                        box-shadow: 0px 4px 19px 0px rgba(119, 147, 65, 0.30);
                                        color: var(--white);
                                        font-family: "Inter";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}