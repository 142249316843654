:root {
  --greycolor: #6c6c76;
  --darkgreycolor: #2b2b2b;
  --lightgreycolor: #8e8e8e;
  --bluecolor: #2f4d8b;
  --blackishcolor: #1c1c1c;
  --lightblackishcolor: #474747;
  --lightgraycolor: #767575;
  --blackcolor: #181818;
  --darkblackcolor: #2b2b2b;
  --lightblackcolor: #1c1c1c;
  --footergreycolor: #5b5b5b;
  --footergreylightcolor: #6a6a6a;
  --footerheadcolor: #2f2f2f;
  --secbackcolor: #f2f2f2;
  --whitecolor: #ffffff;
}

.top {
  position: relative !important;
  z-index: 5 !important;
}
h6 {
  margin-bottom: 0px;
}

.para {
  font-family: "Inter";
  font-size: 21.588px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.864px;
  margin-bottom: 0px;
}
.para-large {
  font-family: "Inter";
  font-size: 26.667px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.067px;
  margin-bottom: 0px;
}
.para-small {
  font-family: "Inter";
  font-size: 24.365px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.975px;
  margin-bottom: 0px;
}
h5 {
  color: var(--darkgreycolor);
  font-family: "Readex Pro";
  font-size: 32px;
  font-style: normal;

  line-height: 42.667px; /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 0px;
}
* {
  padding: 0;
  margin: 0;
  /* outline: 1px solid hotpink; */
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");

@media screen and (max-width: 600px) {
  h2 {
    /* font-size: 20px !important;
    line-height: 120% !important; */
  }
  .para {
    font-size: 15px !important;
    line-height: 120% !important;
  }

  h5 {
    font-size: 18px !important;
    line-height: 120% !important;
  }
  h6 {
    font-size: 20px !important;
    line-height: 120% !important;
  }
  .para-large {
    font-size: 16px !important;
  }
  input,
  textarea {
    font-size: 15px !important;
  }
  button {
    font-size: 16px !important;
  }
}

.herofirstimg,
.herosecondimg,
.featuresimg,
.workimgleft,
.workimg,
.benefitsimgright,
.benefitsimg,
.trustedimg,
.gotquestionimg {
  max-width: 100% !important;
}
@media screen and (max-width: 600px) {
  .herofirstimg,
  .herosecondimg,
  .featuresimg,
  .workimgleft,
  .workimg,
  .benefitsimgright,
  .benefitsimg,
  .trustedimg,
  .gotquestionimg {
    display: none !important;
  }
}

.text-blue {
  color: var(--bluecolor);
}

.invert-1 {
  filter: invert(1);
}

.bg-theme {
  background: #f2f2f2 !important;
}
