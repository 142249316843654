.pricingtab {
  width: 100%;
  padding: 0 50px;
  .containers {
    max-width: 1320px;
    margin: auto;
  }
  .title {
    width: 100%;
    text-align: center;

    h5 {
      color: #2b2b2b;
      text-align: center;
      font-family: "Readex Pro";
      font-size: 2.5vw;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -1.92px;
    }
  }

  .subheading {
    text-align: center;
    margin-top: 10px;
    h5 {
      color: #707070;
      text-align: center;
      font-family: "Inter";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.88px;
    }
  }

  .pricingtabs {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 50px;

    .pricingtabcontent {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .setingHeader {
        // border: 1px solid green;
        // width: 100%;

        .settinghContent {
          width: 100%;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .toggle-container {
            width: 452px;
            height: 56px;
            border-radius: 28px;
            border: 1px solid var(--Main, #2e53da);
            background: var(--BUTTON-BG, #4176d4);
            display: flex;
            align-items: center;
            margin-bottom: 58px;

            .toggle-button {
              border-radius: 25px;
              width: 224px;
              height: 50px;
              background: none; // Set the initial background to none
              border: none;
              margin-left: 2px;
              margin-right: 2px;
              position: relative;

              .good {
                position: absolute;
                top: -30px;
                right: 0;
                // left: 0;
                // width: ;
                // color: black;
                color: #676767;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 180%;
                // background: black;
                background: #e6e6e6;
                padding-inline: 10px;
                border-radius: 20px;
                /* 28.8px */
              }

              &:not(.active) {
                background: none; // Set background for non-active buttons
                color: white;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              &.active {
                background: #fff; // Set background for the active button
                color: var(--BUTTON-BG, #4176d4);
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .pricingtab {
    padding: 10px;
    .title {
      h5 {
        font-size: 25px !important;
      }
    }
    .pricingtabs {
      .pricingtabcontent {
        .setingHeader {
          // border: 1px solid green;
          width: 100%;

          .settinghContent {
            .toggle-container {
              width: 330px;
              height: 56px;
              .toggle-button {
                width: 180px;
              }
            }
          }
        }
      }
    }
  }
}
