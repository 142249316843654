.new-chat {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  .back-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 5px;
  }
  .conversation {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5px;
    .question,
    .answer {
      display: flex;
      align-items: start;
      flex-direction: column;
      width: 100%;
      p {
        margin-bottom: 0;
        padding: 6px 12px;
        border: 1px solid rgb(227, 230, 237);
        background: rgb(227, 230, 237);
        border-radius: 5px;
      }
    }
  }
  .send-message {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
    // border: 1px solid black;
    border-radius: 5px;
    position: relative;
    input {
      position: relative;
      width: 100%;
      color: black;
      padding: 12px;
      border: none;
      border-radius: 5px;
      background: rgb(227, 230, 237);
      font-size: 16px;
      outline: none;
      padding-right: 40px;
    }
    .send-icon {
      position: absolute;
      top: 8px;
      right: 15px;
      cursor: pointer;
    }
  }
}
