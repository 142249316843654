.dashboard-pricetabs {
    width: 100%;


    .pricingtabs-dashboard {
        width: 100%;
        margin-top: 30px;
        // padding-bottom: 50px;
        margin-bottom: 30px;
        position: relative;

        .pricingtabcontent-dashboard {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .setingHeader {
                // border: 1px solid green;
                // width: 100%;

                .settinghContent {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .toggle-containers {
                        width: 350px;
                        height: 56px;
                        border-radius: 28px;
                        border: 1px solid var(--Main, #2e53da);
                        background: var(--BUTTON-BG, #4176d4);
                        display: flex;
                        align-items: center;

                        // margin-bottom: 58px;
                        &[data-theme="dark"] {
                            background: black !important;
                            color: white;
                        }


                        .toggle-button {
                            border-radius: 25px;
                            width: 300px;
                            height: 50px;
                            background: none; // Set the initial background to none
                            border: none;
                            margin-left: 2px;
                            margin-right: 2px;
                            position: relative;


                            .good {
                                position: absolute;
                                top: -30px;
                                right: 0;
                                // left: 0;
                                // width: ;
                                // color: black;
                                color: #676767;
                                font-family: "Inter";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 180%;
                                // background: black;
                                background: #e6e6e6;
                                padding-inline: 10px;
                                border-radius: 20px;
                                /* 28.8px */
                            }

                            &:not(.active) {
                                background: none; // Set background for non-active buttons
                                color: white;
                                font-family: "Inter";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }

                            &.active {
                                background: #fff; // Set background for the active button
                                color: var(--BUTTON-BG, #4176d4);
                                font-family: "Inter";
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                &[data-theme="dark"] {
                                    background: black !important;
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .dashboard-pricetabs {
        width: 100%;



        .pricingtabs-dashboard {
            width: 100%;
            margin-top: 30px;

            .pricingtabcontent-dashboard {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .setingHeader {
                    // border: 1px solid green;
                    width: 100%;

                    .settinghContent {
                        width: 100%;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .toggle-containers {
                            width: 452px;
                            height: 56px;
                            border-radius: 28px;
                            border: 1px solid var(--Main, #2e53da);
                            background: var(--BUTTON-BG, #4176d4);
                            display: flex;
                            align-items: center;
                            margin-bottom: 58px;

                            .toggle-button {
                                border-radius: 25px;
                                width: 224px;
                                height: 50px;
                                background: none; // Set the initial background to none
                                border: none;
                                margin-left: 2px;
                                margin-right: 2px;

                                &:not(.active) {
                                    background: none; // Set background for non-active buttons
                                    color: white;
                                    font-family: "Inter";
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                }

                                &.active {
                                    background: #fff; // Set background for the active button
                                    color: var(--BUTTON-BG, #4176d4);
                                    font-family: "Inter";
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-pricetabs {
        width: 100%;



        .pricingtabs-dashboard {
            width: 100%;
            margin-top: 20px;

            .pricingtabcontent-dashboard {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .setingHeader {
                    // border: 1px solid green;
                    width: 100%;

                    .settinghContent {
                        width: 100%;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .toggle-containers {
                            // width: 452px;
                            width: 230px;
                            height: 40px;
                            border-radius: 28px;
                            border: 1px solid var(--Main, #2e53da);
                            background: var(--BUTTON-BG, #4176d4);
                            display: flex;
                            align-items: center;
                            margin-bottom: 0px;
                            padding-block: 20px;

                            .toggle-button {
                                border-radius: 25px;
                                width: 180px;
                                height: 40px;
                                background: none; // Set the initial background to none
                                border: none;
                                margin-left: 2px;
                                margin-right: 2px;

                                .good {
                                    position: absolute;
                                    top: -20px;
                                    right: 0;
                                    // left: 0;
                                    // width: ;
                                    // color: black;
                                    color: #676767;
                                    font-family: "Inter";
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 180%;
                                    // background: black;
                                    background: #e6e6e6;
                                    padding-inline: 10px;
                                    border-radius: 20px;
                                    /* 28.8px */
                                }

                                &:not(.active) {
                                    background: none; // Set background for non-active buttons
                                    color: white;
                                    font-family: "Inter";
                                    font-size: 13px !important;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                }

                                &.active {
                                    background: #fff; // Set background for the active button
                                    color: var(--BUTTON-BG, #4176d4);
                                    font-family: "Inter";
                                    font-size: 13px !important;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}